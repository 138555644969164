
import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Badge
} from "shards-react";

import PageTitle from "../components/common/PageTitle";

import {API} from 'aws-amplify';

const listDevicesMinimal = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        MACAddress
        Name
        CurrentState {
          Type
        }
        Spectators
        Maintainers
        Tags
        owner
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;

function renderDurationString (duration) {
  let output = "";
  /* duration initially in seconds */
  if(duration > 0)
  {
    output = (duration % 60) + "s " + output
    duration = Math.floor(duration / 60);
    /* duration now in minutes */
    if(duration > 0)
    {
      output = (duration % 60) + "m " + output
      duration = Math.floor(duration / 60);
      /* duration now in hours */
      if(duration > 0)
      {
        output = (duration % 24) + "h " + output
        duration = Math.floor(duration / 24);
        /* duration now in days */
        if(duration > 0)
        {
          output = duration + "d " + output
        }
      }
    }
  }
  else
  {
    output = "None"
  }
  return output.trim();
}

class AllDevices extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      // First list of posts.
      devices: [],
      activeDevices: [],
      loading: true,
    };
    this.handleDevicesQueryResult = this.handleDevicesQueryResult.bind(this);
  }



  handleDevicesQueryResult(value)
  {
    let queryVariables = {
      limit: 1000000
    }
    if(this.props.match.params.userid !== undefined)
    {
      console.log("Filtering using user ID: " + this.props.match.params.userid)
      queryVariables.filter = {owner: {eq: this.props.match.params.userid}}
    } else if(this.props.match.params.macaddress !== undefined)
    {
      console.log("Filtering using mac: " + this.props.match.params.macaddress)
      queryVariables.filter = {MACAddress: {eq: this.props.match.params.macaddress}}
    }

    let devices = value.data.listDevices.items;
    this.setState(prevState => ({
      devices: devices,
      activeDevices: (prevState.activeDevices===undefined?devices.filter(dev=>!dev._deleted):prevState.activeDevices.concat(devices.filter(dev=>!dev._deleted)))
          .sort((a,b)=>a._lastChangedAt>b._lastChangedAt?-1:11),
      loading: false
    }));
    console.log("Next Token")
    console.log(value.data.listDevices?.nextToken)
    if(value.data.listDevices.nextToken?.length > 2)
    {
      queryVariables.nextToken = value.data.listDevices.nextToken;
      console.log("Running deeper query")
      API.graphql( {query: listDevicesMinimal, variables: queryVariables}).then(this.handleDevicesQueryResult).catch(err=>
      {
        console.log("Graphql err: ", err);
      })
    }
  }

  componentDidMount() {
    let queryVariables = {
      limit: 1000000
    }
    if(this.props.match.params.userid !== undefined)
    {
      console.log("Filtering using user ID: " + this.props.match.params.userid)
      queryVariables.filter = {owner: {eq: this.props.match.params.userid}}
    } else if(this.props.match.params.macaddress !== undefined)
    {
      console.log("Filtering using mac: " + this.props.match.params.macaddress)
      queryVariables.filter = {MACAddress: {eq: this.props.match.params.macaddress}}
    }
    API.graphql( {query: listDevicesMinimal, variables: queryVariables}).then(this.handleDevicesQueryResult).catch(err=>
    {
      console.log("Graphql err: ", err);
    })
  }

  render() {

    const {
      activeDevices,
      loading
    } = this.state;
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title={"Your Devices " + (loading?"(loading)":("(" + activeDevices.length + " device" + (activeDevices.length>1?"s":"") + ")"))} subtitle="Devices"
                     className="text-sm-left"/>
        </Row>
        {/* First Row of Devices */}
        <Row>
          {activeDevices.map((dev, idx) =>
          {
            let timeSinceActivity = ((new Date()).getTime()-(new Date(dev._lastChangedAt)).getTime())/1000;
            return (
            <Col lg="3" md="6" sm="12" className="mb-4" key={idx} onClick={()=>window.location=("/device/" + dev.id)}>
              <Card small className="card-post card-post--1">
                <div
                  className="card-post__image"
                  style={{backgroundImage: `url(${require("../images/avatars/WA-Pro.png")})`}}
                >
                  <div className={"top-badges"} style={{display: "flex", padding: "5%", justifyContent: "center"}}>
                    <Badge
                        pill
                        style={{marginLeft: "1%", marginRight: "auto"}}
                    >
                      {dev.MACAddress}
                    </Badge>
                    <Badge theme={timeSinceActivity < 1900?"success":timeSinceActivity < 86400?"warning":"danger"}
                        pill
                        style={{marginLeft: "auto", marginRight: "1%"}}
                    >
                      {renderDurationString(Math.ceil(timeSinceActivity))}
                    </Badge>
                  </div>
                  <div className="card-post__author d-flex">
                    <p>
                      {dev.CurrentState.Type}
                    </p>
                  </div>
                </div>
                <CardBody>
                  <h5 className="card-title">
                    <a href={ "/device/" + dev.id} className="text-fiord-blue">
                      {dev.Name?dev.Name:"Unnamed Device"}
                    </a>
                  </h5>
                  <p className="card-text d-inline-block mb-3">This device was setup at:</p>
                  <br/>
                  <span className="text-muted">{dev.createdAt}</span>
                </CardBody>
              </Card>
            </Col>
          )})}
        </Row>
      </Container>
    );
  }
}
export default AllDevices;
