
import React from "react";
import {
  Col,
  Container,
  Row
} from "shards-react";

import PageTitle from "../components/common/PageTitle";

import Events from "../components/device/Events"

import {API} from 'aws-amplify';
import { getDevice } from "../graphql/queries";
import DeviceActions from "../components/device/DeviceActions";
import Graph from "../components/device/Graph";
import ErrorBoundary from "../components/ErrorBoundary";
import Digests from "../components/device/Digests";

class Device extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // First list of posts.
      deviceId: props.match.params.id,
      loading: true
    };
    this.refreshDeviceInfo = this._refreshDeviceInfo.bind(this);
  }



  componentDidMount() {
    this._refreshDeviceInfo();
  }

  _refreshDeviceInfo()
  {
    API.graphql( {query: getDevice, variables:{id: this.state.deviceId}}).then(value =>
    {
      if(value.data.getDevice)
      {
        console.log(value.data.getDevice)
        this.setState(prevState => ({
          device: value.data.getDevice,
          loading: false
        }));
      }
      else
      {
        this.setState(prevState => ({
          device: undefined,
          loading: false
        }));
      }
    }).catch(err=>
    {
      console.log("Graphql err: ", err);
      this.setState(prevState => ({
        device: err.data.getDevice,
        loading: false
      }));
    })
  }

  render() {

    const {
      device,
      deviceId,
      loading
    } = this.state;
    return loading ? (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Loading device" subtitle={deviceId}
                     className="text-sm-left"/>
        </Row>
      </Container>
    ) : device ? (
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title={device.MACAddress} subtitle={device.id}
                       className="text-sm-left"/>
          </Row>
          <Row className="page-header py-4">
            <Col lg="12" md="12" sm="12" xs="12" className="mb-4">
              <ErrorBoundary customMessage={"Failed to render latest reading graph"}>
                <Graph device={device} refreshDevice={this.refreshDeviceInfo}/>
              </ErrorBoundary>
            </Col>
          </Row>

          <Row className="page-header py-4">

            {/* Actions */}
            <Col lg="4" md="12" sm="12" xs="12" className="mb-4">
              <ErrorBoundary customMessage={"Failed to render device actions"}>
                <DeviceActions device={device} refreshDevice={this.refreshDeviceInfo}/>
              </ErrorBoundary>
            </Col>

            {/* Events */}
            <Col lg="4" md="12" sm="12" className="mb-4">
              <ErrorBoundary customMessage={("Failed to render device events list")}>
                <Events events={device.Events}/>
              </ErrorBoundary>
            </Col>

            {/* Digests */}
            <Col lg="4" md="12" sm="12" className="mb-4">
              <ErrorBoundary customMessage={("Failed to render device digest list")}>
                <Digests digests={device.Digests}/>
              </ErrorBoundary>
            </Col>
          </Row>
        </Container>
      ) :
      (
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title="Device not found" subtitle={deviceId}
                       className="text-sm-left"/>
          </Row>
        </Container>
      );
  }
}
export default Device;
