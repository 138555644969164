import {
    Button,
    ButtonGroup,
    Col,
    Container,
    Form,
    FormGroup,
    FormInput, FormSelect,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import React from "react";

export default class MacEntryStage extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            macValid: false,
            mac: "",
            prefix: "98:8B:AD:"
        };

        this.handleNext = this.handleNext.bind(this);
        this.validateMac = this.validateMac.bind(this);
    }

    handleNext(e)
    {
        e.preventDefault();
        console.log("SUBMITTING " + this.state.prefix + this.state.mac)
        /* TODO: check mac database */

        if(this.props.handleSuccess !== undefined)
        {
            if(this.state.macValid === true)
            {
                this.props.handleSuccess(this.state.prefix + this.state.mac);
            }
        }
    }

    validateMac(e)
    {
        let input = e.target.value;
        let valid = false;
        let mac = input.toUpperCase().split('').filter(c=>['0','1','2','3','4','5','6','7','8','9','A','B','C','D','E','F'].includes(c)).slice(-6).join('');
        if(mac.length === 6)
        {
            mac = mac.replace(/(..)/g, '$1:').slice(0,-1);
            valid = true;
        }

        this.setState(ps => {
            ps.macValid = valid;
            ps.mac = mac;
            return ps;
        })
    }

    render() {
        return (
            <Container fluid className="main-content-container px-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-4">
                    <PageTitle sm="4" title={"MAC entry"} subtitle="Programming"
                               className="text-sm-left"/>
                </Row>
                <Row>
                    <h2>
                        <Form onSubmit={this.handleNext}>
                            <FormGroup>
                                <label htmlFor="macAddress">MAC Address</label>
                                <InputGroup className="mb-3">
                                    <InputGroupAddon type="prepend">
                                        <InputGroupText>{this.state.prefix}</InputGroupText>
                                    </InputGroupAddon>
                                    <FormInput autoFocus invalid={!this.state.macValid} value={this.state.mac} id="macAddress" placeholder="XX:XX:XX" onChange={this.validateMac}/>
                                </InputGroup>
                            </FormGroup>
                        </Form>
                    </h2>
                </Row>
                <Row noGutters className="page-header py-4">
                    <ButtonGroup vertical>
                        <Button size="lg" onClick={this.handleNext}>Next</Button>
                    </ButtonGroup>
                </Row>
            </Container>)
    }
}