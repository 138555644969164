
import React from "react";
import {
  Container, FormInput,
  Row
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import {API, Auth} from 'aws-amplify';

const AWS = require('aws-sdk')
AWS.config.update({region:'eu-west-1'});

const USER_POOL_ID = 'eu-west-1_S465LzBpt'


const listDevicesMinimal = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        MACAddress
        Name
        CurrentState {
          Type
        }
        Spectators
        Maintainers
        Tags
        owner
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;

class SearchMac extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      searchIdx: 0,
      loading: false,
    };
    this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
    this.searchForMac = this.searchForMac.bind(this);
    this.handleDevicesQueryResult = this.handleDevicesQueryResult.bind(this);
  }

  handleSearchTermChange(event)
  {

    let { value } = event.target;
    if(value.length >= 3)
    {
      value = value.toUpperCase();
      this.setState(ps=>
      {
        ps.searchTerm = value;
        ps.devices = [];
        ps.activeDevices = [];
        ps.loading = true;
        ps.searchIdx++;
        return ps;
      }, ()=>
      {
        this.searchForMac();
      })
    }
  }

  searchForMac()
  {
    console.log("starting search")
    console.log(this.state.activeDevices)
    let queryVariables = {
      limit: 1000000,
      filter: {MACAddress: {contains: this.state.searchTerm}}
    }
    const {searchIdx} = this.state;
    API.graphql( {query: listDevicesMinimal, variables: queryVariables}).then((res)=>this.handleDevicesQueryResult(res, searchIdx)).catch(err=>
    {
      console.log("Graphql err: ", err);
    })
  }

  handleDevicesQueryResult(value, searchIdx)
  {
    let queryVariables = {
      limit: 1000000,
      filter: {MACAddress: {contains: this.state.searchTerm}}
    }

    if(searchIdx !== this.state.searchIdx)
    {
      console.log("Search is old, abandon")
      return;
    }

    let devices = value.data.listDevices.items;
    this.setState(prevState => ({
      devices: devices,
      activeDevices: (prevState.activeDevices===undefined?devices.filter(dev=>!dev._deleted):prevState.activeDevices.concat(devices.filter(dev=>!dev._deleted)))
          .sort((a,b)=>a._lastChangedAt>b._lastChangedAt?-1:11),
      loading: value.data.listDevices.nextToken?.length > 2??false
    }));
    console.log("Next Token")
    console.log(value.data.listDevices.nextToken)
    if(value.data.listDevices.nextToken?.length > 2)
    {
      queryVariables.nextToken = value.data.listDevices.nextToken;
      console.log("Running deeper query")
      API.graphql( {query: listDevicesMinimal, variables: queryVariables}).then((res)=>this.handleDevicesQueryResult(res, searchIdx)).catch(err=>
      {
        console.log("Graphql err: ", err);
      })
    }
  }

  componentDidMount() {
    Auth.currentCredentials().then(cred=>
    {
      console.log("using credentials: "+ JSON.stringify(cred) );
      AWS.config.credentials = cred;

      AWS.config.update({
        accessKeyId: cred.accessKeyId,
        secretAccessKey: cred.secretAccessKey,
        sessionToken: cred.sessionToken,
        region: 'eu-west-1'
      });
      let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
      this.setState(ps=>
      {
        ps.cisp = cognitoidentityserviceprovider;
        return ps;
      })
    })
  }

  render() {

    const {
      activeDevices,
      searchTerm,
        loading
    } = this.state;
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title={"MAC Search"} subtitle="Devices"
                     className="text-sm-left"/>
          <FormInput placeholder="Enter MAC address" className="mb-2" onChange={this.handleSearchTermChange} />
        </Row>
        {/* First Row of Devices */}
        <Row>
          <table>
            <th>
              <h3>{loading?"Loading...":activeDevices?"Results for '" + searchTerm + "' (" + activeDevices?.length + ")":"Please enter a search term"}</h3>

            </th>
            {activeDevices?.map((device, idx)=>{
              return(
                  <tr key={idx}>
                    <a href={"/all-devices/mac/"+device.MACAddress}>
                      {device.MACAddress + " (" + device.Name + ")"}
                    </a>
                  </tr>
              )
            })}
          </table>
        </Row>
      </Container>
    );
  }
}
export default SearchMac;
