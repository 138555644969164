import React from "react";
import {FormCheckbox, Button, Card, Badge, CardBody, CardHeader} from "shards-react";

import PageTitle from "../components/common/PageTitle";

import {API} from 'aws-amplify';
import {disableDigestEmails, disableEventEmails, enableDigestEmails, enableEventEmails} from "../graphql/mutations";

import './email-preferences.css'

const listDevicesMinimal = /* GraphQL */ `
    query ListDevices(
        $filter: ModelDeviceFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                MACAddress
                Name
                CurrentState {
                    Type
                }
                Spectators
                Maintainers
                Tags
                EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                }
                owner
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
            }
            nextToken
            startedAt
        }
    }
`;

class DevicePreferences extends React.Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            events: props.device.EmailSettings.EventsEnabled,
            reports: props.device.EmailSettings.DigestEnabled
        };
        this.handleEventPreferenceChange = this.handleEventPreferenceChange.bind(this);
        this.handleDigestPreferenceChange = this.handleDigestPreferenceChange.bind(this);
    }

    handleEventPreferenceChange()
    {
        let newState = !this.state.events;
        this.setState((ps) => {
            ps.events = newState;
            return ps
        })
        let query = newState?enableEventEmails:disableEventEmails;

        API.graphql({
            query: query,
            variables: {id: this.props.device.id, expectedVersion: this.props.device._version}
        }).then(value => {
            this.props.refreshDevices();
        }).catch(err => {
            console.log("Failed to update event email state")
            console.log(err);
        })
    }

    handleDigestPreferenceChange()
    {
        let newState = !this.state.reports;
        this.setState((ps) => {
            ps.reports = newState;
            return ps
        })
        let query = newState?enableDigestEmails:disableDigestEmails;

        API.graphql({
            query: query,
            variables: {id: this.props.device.id, expectedVersion: this.props.device._version}
        }).then(value => {
            this.props.refreshDevices();
        }).catch(err => {
            console.log("Failed to update digest email state")
            console.log(err);
        })
    }

    render() {


        const {
            events,
            reports
        } = this.state;
        const {
            device
        } = this.props;
        return (
            <div>
                <Card small className="card-post card-post--1">
                    <CardHeader>
                        <h5 className="card-title">
                            {(device?.Name ?? "Unnamed") + " "}
                        </h5>
                        <h6>
                            (Wireless Alert Pro)
                        </h6>
                    </CardHeader>
                    <CardBody className={"preference-body"} style={{paddingTop: 0}}>
                        <FormCheckbox
                            className={"enable-cb"}
                            toggle
                            checked={events}
                            onChange={this.handleEventPreferenceChange}>
                            Event Emails Enabled
                        </FormCheckbox>
                        <br/>
                        <FormCheckbox
                            className={"enable-cb"}
                            toggle
                            checked={reports}
                            onChange={this.handleDigestPreferenceChange}>
                            Summary Report Emails Enabled
                        </FormCheckbox>
                    </CardBody>
                </Card>
                <br/>
            </div>
        );
    }
}

class Preferences extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            activeDevices: [],
            loading: true
        };
        this.handleDevicesQueryResult = this.handleDevicesQueryResult.bind(this);
        this.disableAll = this.disableAll.bind(this);
        this.refreshDevices = this.refreshDevices.bind(this);
    }

    handleDevicesQueryResult(value, depth) {
        if(depth === undefined)
        {
            depth = 0;
            this.setState((ps) => {
                ps.activeDevices = [];
                return ps
            })
        }
        let queryVariables = {
            filter: {or: [{owner: {eq: this.props.user.username}}, {Spectators: {contains: this.props.user.username}}, {Maintainers: {contains: this.props.user.username}}]},
            limit: 1000000
        };
        let devices = value.data.listDevices.items;
        this.setState(prevState => ({
            devices: devices,
            activeDevices: prevState.activeDevices === undefined ? devices.filter(dev => !dev._deleted) : prevState.activeDevices.concat(devices.filter(dev => !dev._deleted)),
            loading: false
        }));
        console.log("Next Token")
        console.log(value.data.listDevices.nextToken)
        if (value.data.listDevices.nextToken) {
            queryVariables.nextToken = value.data.listDevices.nextToken;
            console.log("Running deeper query")
            API.graphql({
                query: listDevicesMinimal,
                variables: queryVariables
            }).then((res)=>this.handleDevicesQueryResult(res, ++depth)).catch(err => {
                console.log("Graphql err: ", err);
            })
        }
    }

    refreshDevices() {
        let queryVariables = {
            filter: {or: [{owner: {eq: this.props.user.username}}, {Spectators: {contains: this.props.user.username}}, {Maintainers: {contains: this.props.user.username}}]},
            limit: 1000000
        };
        API.graphql({
            query: listDevicesMinimal,
            variables: queryVariables
        }).then(this.handleDevicesQueryResult).catch(err => {
            console.log("Graphql err: ", err);
        })
    }

    componentDidMount() {
        this.refreshDevices();
    }

    render() {

        const {
            activeDevices,
            loading
        } = this.state;
        return loading === false ? (
            <div class={"main-container"}>
                <div class={"introduction"}>
                    <h1>
                        Email Preferences
                    </h1>
                    <p>
                        Change the toggles below to adjust your email preferences or simply click 'Disable All' to turn
                        off all emails
                    </p>
                </div>
                <div class={"central-container"}>
                    {activeDevices.map((dev, idx) => (
                        <DevicePreferences key={idx} device={dev} refreshDevices={this.refreshDevices}/>
                    ))}
                </div>
                <div className={"button-div"}>
                    <Button className={"central-container"} size="lg" onClick={() => this.disableAll()}>Disable All</Button>
                </div>
            </div>
        ) : (<p>Loading</p>);
    }

    disableAll() {
        this.state.activeDevices.forEach((device, idx) => {

            API.graphql({
                query: disableDigestEmails,
                variables: {id: device.id, expectedVersion: device._version}
            }).then(value => {
            }).catch(err => {
                console.log("Failed to update digest email state")
                console.log(err);
            })
            API.graphql({
                query: disableEventEmails,
                variables: {id: device.id, expectedVersion: device._version}
            }).then(value => {
                if(idx === (this.state.activeDevices.length-1))
                {
                    this.refreshDevices();
                }
            }).catch(err => {
                console.log("Failed to update event email state")
                console.log(err);
            })
        })
    }
}

export default Preferences;