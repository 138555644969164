import {
    Button,
    ButtonGroup,
    Col,
    Container,
    Form,
    FormGroup,
    FormInput, FormSelect,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import React from "react";

export default class SetupStage extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            formValidity:
                {
                    worksOrder: false
                },
            location: "UK",
            worksOrderNumber: ""
        };

        this.handleNext = this.handleNext.bind(this);
        this.validateWorksOrder = this.validateWorksOrder.bind(this);
        this.updateLocation = this.updateLocation.bind(this);
        this.generatePrefix = this.generatePrefix.bind(this);
    }

    handleNext(e)
    {
        e.preventDefault();
        if(this.props.handleSuccess !== undefined)
        {
            if(this.state.formValidity.worksOrder === true)
            {
                this.props.handleSuccess(this.generatePrefix(this.state.location) + this.state.worksOrderNumber + "/A");
            }
        }
    }

    generatePrefix(location)
    {
        switch (location)
        {
            case "HK":
            case "FE":
                return "WOHK/";
            case "UK":
            default:
                return "WO/";
        }
    }

    validateWorksOrder(e)
    {
        let valid = false;
        let input = e.target.value.toUpperCase();
        let location = undefined;

        let number = Number(input.split('').filter(c=>['0','1','2','3','4','5','6','7','8','9'].includes(c)).join(''));

        if(input.endsWith("/A"))
        {
            if(input.startsWith("WOHK"))
            {
                location = "FE";
            }
            else
            {
                location = "UK";
            }
            e.target.value = number;
        }


        if(!isNaN(number))
        {
            if(number > 1000 && number < 1000000)
            {
                valid = true;
            }
        }

        this.setState(ps => {
            ps.formValidity.worksOrder = valid;
            ps.worksOrderNumber = String(number);
            if(location !== undefined)
            {
                ps.location = location;
            }
            return ps;
        })
    }

    updateLocation(e)
    {
        let input = e.target.value;

        this.setState(ps => {
            ps.location = input;
            return ps;
        })
    }

    render() {
        return (
            <Container fluid className="main-content-container px-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-4">
                    <PageTitle sm="4" title={"Setup"} subtitle="Programming"
                               className="text-sm-left"/>
                </Row>
                <Row>
                    Please scan works order barcode or manually select location and enter works order number
                </Row>
                <br/>
                <Row>
                    <h2>
                        <Form onSubmit={this.handleNext}>
                            <FormGroup inline>
                                <label htmlFor="factorySelect">Factory Location</label>
                                <FormSelect value={this.state.location} id="factorySelect" onChange={this.updateLocation}>
                                    <option>UK</option>
                                    <option>FE</option>
                                </FormSelect>
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="worksOrder">Works Order</label>
                                <InputGroup className="mb-3">
                                    <InputGroupAddon type="prepend">
                                        <InputGroupText>{this.generatePrefix(this.state.location)}</InputGroupText>
                                    </InputGroupAddon>
                                    <FormInput autoFocus invalid={!this.state.formValidity.worksOrder} id="worksOrder" placeholder="Works Order" onChange={this.validateWorksOrder}/>
                                    <InputGroupAddon type="append">
                                        <InputGroupText>/A</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Form>
                    </h2>
                </Row>
                <Row noGutters className="page-header py-4">
                    <ButtonGroup vertical>
                        <Button size="lg" onClick={this.handleNext}>Next</Button>
                    </ButtonGroup>
                </Row>
            </Container>)
    }
}