import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Row,
  Col
} from "shards-react";

import {shortTimeFormatter} from "../../utils/formatters"

  const DigestBody = (props) =>
  {
    const digest = props.digest;
    let response;
    response = (<div>
      <p className="m-0 my-1 mb-2 text-muted">{JSON.stringify(digest.DeviceDigest)}</p>
      {digest.ChannelDigests.map((cd, idx)=>(
          <p key={idx} className="m-0 my-1 mb-2 text-muted">{JSON.stringify(cd)}</p>
      ))}
    </div>)
    return response;
  }

const Digests = ({title, digests}) => (
  <Card small className="blog-comments">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
    </CardHeader>

    <CardBody className="p-0">
      {digests.sort((digestA, digestB)=>digestB.DeviceDigest.StartTimestamp-digestA.DeviceDigest.StartTimestamp).map((digest, idx) => (
        <div key={idx} className="blog-comments__item d-flex p-3">

          {/* Content */}
          <div className="blog-comments__content" style={{width:"99%", margin: "auto"}}>
            {/* Content :: Title */}
            <div className="blog-comments__meta text-mutes">
              <span className="text-mutes">{shortTimeFormatter(digest.DeviceDigest.StartTimestamp)}</span>
              <span className="text-mutes"> - {shortTimeFormatter(digest.DeviceDigest.EndTimestamp)}</span>
            </div>

            {/* Content :: Body */}
            <DigestBody digest={digest}/>
          </div>
        </div>
      ))}
    </CardBody>

    <CardFooter className="border-top">
      <Row>
        <Col className="text-center view-report">
          <Button theme="white" type="submit">
            Previous
          </Button>
          <Button theme="white" type="submit">
            Next
          </Button>
        </Col>
      </Row>
    </CardFooter>
  </Card>
);

Digests.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The discussions dataset.
   */
  events: PropTypes.array
};

Digests.defaultProps = {
  title: "Digests",
  events: []
};

export default Digests;
