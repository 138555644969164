import React from "react";
import {Button, ButtonGroup, Modal, ModalHeader, ModalBody, FormGroup, FormInput, Form} from "shards-react";
import {API} from 'aws-amplify';
import {
  updateRenderSettings,
    updateChannelName
} from "../../../graphql/mutations";

const RenderSettingPresets =
  {
    "TEMPERATURE":
      [

        {
          Name: "Fahrenheit",
          RenderSettings:
            {
              Unit: "°F",
              ScaleFactor: 9/5,
              Offset: 32
            }
        },
        {
          Name: "Celsius",
          RenderSettings:
            {
              Unit: "°C",
              ScaleFactor: 1,
              Offset: 0
            }
        },
        {
          Name: "Kelvin",
          RenderSettings:
            {
              Unit: "K",
              ScaleFactor: 1,
              Offset: 273.15
            }
        }
      ],
    "HUMIDITY":
      [
        {
          Name: "%RH",
          RenderSettings:
            {
              Unit: "%RH",
              ScaleFactor: 1,
              Offset: 0
            }
        }
      ]
  }

class ProbeActionsMenu extends React.Component {
  constructor(props) {
    super(props);
      let state =
          {
              renderSettingModalOpen: [],
              editNameModalOpen: [],
              channelNames:[]
          }
    props.device.CurrentState.Channels.forEach((channel, idx)=>
    {
        state.renderSettingModalOpen[idx] = false
        state.editNameModalOpen[idx] = false
    })
    this.state = state;
    this.handleRenderSettingsChange = this._handleRenderSettingsChange.bind(this);
    this.handleApplyNewRenderSettings = this._handleApplyNewRenderSettings.bind(this);
    this.handleNameChanged = this._handleNameChanged.bind(this);
    this.changeChannelName = this._changeChannelName.bind(this);
  }

  _handleRenderSettingsChange(channelIdx)
  {
    this.setState(ps=>{ps.renderSettingModalOpen[channelIdx] = !ps.renderSettingModalOpen[channelIdx]; return ps})
  }

  _handleApplyNewRenderSettings(channelIdx, preset)
  {
    API.graphql({query: updateRenderSettings, variables: {id: this.props.device.id, channelIdx: channelIdx ,newRenderSettings: preset.RenderSettings, expectedVersion:this.props.device._version}}).then(value => {
      this.props.refreshDevice();
      this.setState(ps=>{ps.renderSettingModalOpen[channelIdx] = false; return ps})
    }).catch(err=>
    {
      alert('Failed to set new version');
    })
  }

    handleChannelRename(channelIdx)
    {
        this.setState(ps=>{ps.editNameModalOpen[channelIdx] = !ps.editNameModalOpen[channelIdx]; return ps})
    }

    _handleNameChanged(e, channelIdx)
    {
        let names = this.state.channelNames;
        names[channelIdx] = e.target.value
        this.setState({channelNames: names})
    }

    _changeChannelName(channelIdx)
    {
        let name = this.state.channelNames[channelIdx];
        if(name && (name.length > 0 && name.length < 30))
        {
            console.log("Changing name to " + name)
            API.graphql({query: updateChannelName, variables: {id: this.props.device.id, channelIndex: channelIdx ,newName: name, expectedVersion:this.props.device._version}}).then(value => {
                this.props.refreshDevice();
                this.handleChannelRename(channelIdx);
            }).catch(err=>
            {
                alert('Failed to update channel name');
            })
        }
        else
        {
            alert("Name Invalid")
        }
    }

    ProbeDisplay = (props) =>
  {
    //{this.props.device.CurrentState.Channels.map((channel, idx) => (<this.channelDisplay device={this.props.device} channelIdx={idx}/>))}
    const {device, probeIdx} = props;
    const probe = device.PendingState.Probes[probeIdx];
    if(probe)
    {
      return (
          <div>
              <p className="m-0 my-1 mb-2">&nbsp;
                  {this.props.device.CurrentState.Probes.length > 1?"Probe " + probeIdx + ":":"Details:"}</p>

              <p className="m-0 my-1 mb-2 text-muted">&nbsp; &nbsp; SN: {probe.SN}</p>
              <p className="m-0 my-1 mb-2 text-muted">&nbsp; &nbsp; Type: {probe.Type}</p>
              <p className="m-0 my-1 mb-2 text-muted">&nbsp; &nbsp; Sample period: {probe.SamplePeriod} seconds</p>
          </div>)
    }
    else
    {
      return (<p className="m-0 my-1 mb-2 text-muted">Unknown Channel</p>)
    }
  }

  render() {
    return (
      <div>
        <div className="blog-comments__meta text-mutes">
            {this.props.device.CurrentState.Probes.length > 1?"Probes":"Probe"}
          {this.props.device.CurrentState.Probes.map((channel, idx) => (<this.ProbeDisplay key={idx} device={this.props.device} probeIdx={idx}/>))}
        </div>
        <div className="blog-comments__actions">
        </div>
      </div>)
  }
}

const ProbeActions =
  {
    body: ProbeActionsMenu,
    title: "Probes"
  }

export default ProbeActions
