import React from "react";
import {Button, ButtonGroup, Modal, ModalHeader, ModalBody, FormGroup, FormInput, Form, FormSelect} from "shards-react";
import {API} from 'aws-amplify';
import {
    updateCheckInPeriod
} from "../../../graphql/mutations";
import moment from "moment";


class CheckInActionsMenu extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
      }
  }

    setCheckInInterval(interval)
    {
        console.log("Update to be every " + interval + " seconds")

        API.graphql({query: updateCheckInPeriod, variables: {id: this.props.device.id, checkInPeriod: interval, expectedVersion:this.props.device._version}}).then(value => {
            console.log(value)
            this.props.refreshDevice();
        }).catch(err=>
        {
            alert('Failed to update digest schedule');
        })
    }

  render() {
      const schedule = this.props.device.CurrentState.CheckInSchedule;
      const now = Date.now() / 1000;
      const nextDue = schedule.Datum + ((Math.floor(Math.abs(schedule.Datum - now)/schedule.Period) + 1) * schedule.Period)
    return (
      <div>
        <div className="blog-comments__meta text-mutes">
          Check Ins
            <div>
                <p className="m-0 my-1 mb-2 text-muted">
                    Interval: {this.renderDurationString(this.props.device.CurrentState.CheckInSchedule.Period, true)}
                    {this.props.device.CurrentState.CheckInSchedule.Period !== this.props.device.PendingState.CheckInSchedule.Period?(" ("+this.renderDurationString(this.props.device.PendingState.CheckInSchedule.Period, true)+" Pending)"):""}</p>
                <p className="m-0 my-1 mb-2 text-muted">Next due at: {moment(nextDue*1000).format('HH:mm:ss')}</p>
                <ButtonGroup size="sm">
                    <Button theme="white" onClick={() => this.setCheckInInterval(300)}>
                  <span className="text-success">
                    <i className="material-icons">speed</i>
                  </span>{" "}
                        Fast Mode (5 min)
                    </Button>
                    <Button theme="white" onClick={() => this.setCheckInInterval(1800)}>
                  <span className="text-success">
                    <i className="material-icons">speed</i>
                  </span>{" "}
                        Normal Mode (30 min)
                    </Button>
                    <Button theme="white" onClick={() => this.setCheckInInterval(5400)}>
                  <span className="text-success">
                    <i className="material-icons">speed</i>
                  </span>{" "}
                        Slow Mode (90 min)
                    </Button>
                </ButtonGroup>
            </div>
        </div>
        <div className="blog-comments__actions">
        </div>
      </div>)
  }

    renderDurationString(duration, excludeZeroValues) {
        let output = "";
        /* duration initially in seconds */
        if (duration > 0) {
            if((false === excludeZeroValues) || ((duration % 60) > 0))
            {
                output = (duration % 60) + "s " + output
            }
            duration = Math.floor(duration / 60);
            /* duration now in minutes */
            if (duration > 0) {
                if((false === excludeZeroValues) || ((duration % 60) > 0))
                {
                    output = (duration % 60) + "m " + output
                }
                duration = Math.floor(duration / 60);
                /* duration now in hours */
                if (duration > 0) {
                    if((false === excludeZeroValues) || ((duration % 24) > 0))
                    {
                        output = (duration % 24) + "h " + output
                    }
                    duration = Math.floor(duration / 24);
                    /* duration now in days */
                    if (duration > 0) {
                        output = duration + "d " + output
                    }
                }
            }
        } else {
            output = "None"
        }
        return output.trim();
    };
}

const CheckInActions =
  {
    body: CheckInActionsMenu,
    title: "Check In"
  }

export default CheckInActions
