import {
    Card, CardBody, CardHeader,
    Col,
    Container,
    Progress,
    Row
} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import React from "react";
import moment from "moment";

export default class TestingStage extends React.Component
{
    constructor(props) {
        super(props);

        this.runTests = this.runTests.bind(this);
        this.checkMac = this.checkMac.bind(this);
        this.setMac = this.setMac.bind(this);
        this.calibrate = this.calibrate.bind(this);
        this.consumeMac = this.consumeMac.bind(this);

        this.state = {
            currentTest: undefined,
            tests: [
                {
                    name: "Validate MAC Address",
                    action: this.checkMac,
                    progress: 0,
                    success: false,
                    complete: false
                },
                {
                    name: "Set MAC Address",
                    action: this.setMac,
                    progress: 0,
                    success: false,
                    complete: false
                },
                {
                    name: "Consume MAC Address",
                    action: this.consumeMac,
                    progress: 0,
                    success: false,
                    complete: false
                },
                {
                    name: "Resistance Calibration",
                    action: this.calibrate,
                    progress: 0,
                    success: false,
                    complete: false
                }
            ]};
    }

    async checkMac(testIdx)
    {
        let mac = this.props.mac;
        let failed = true;
        let existingMac = await this.sendCommand("mac", 1000, undefined);
        console.log("Existing mac: " + existingMac);
        if(existingMac !== undefined)
        {
            existingMac = existingMac.trim();
            existingMac = existingMac.split("=")[1];
            if(existingMac.startsWith("98:8B:AD"))
            {
                /* already has a corintech MAC */
                if(existingMac === this.props.mac)
                {
                    failed = false;
                }
            }
            else
            {
                /* MAC not yet set */
                const res = await fetch('https://xkghhip63m.execute-api.eu-west-1.amazonaws.com/Prod/v1/status/' + mac, {headers:{'x-api-key': 's4td5omTka64eu1t3yM411f2BMHEfoXN4joRClBz'}});
                const json = await res.json();
                console.log("MAC database: " + JSON.stringify(json))
                if(Object.keys(json).length === 0)
                {
                    console.log("MAC not taken")
                    failed = false;
                }
                else
                {
                    alert("MAC Already used please contact CTUK engineering")
                }
            }
        }
        else
        {

        }

        await this.setState(ps => {
            ps.tests[testIdx].progress = 100;
            ps.tests[testIdx].success = !failed;
            ps.tests[testIdx].complete = true;
            return ps;
        });
        return !failed;
    }

    async setMac(testIdx)
    {
        let mac = this.props.mac;
        let response = await this.sendCommand("mac=" + mac, 1000, undefined);
        console.log("set mac response: " + response)
        let pass = !response.toUpperCase().includes("ERROR");
        this.setState(ps => {
            ps.tests[testIdx].progress = 100;
            ps.tests[testIdx].success = pass;
            ps.tests[testIdx].complete = true;
            return ps;
        });
        return pass;
    }

    async consumeMac(testIdx)
    {
        let mac = this.props.mac;
        let failed = true;
        const testData =
            {
                MAC: mac,
                TestDate: moment().format("YYYY-MM-DD"),
                Product: "Wireless Alert Pro",
                FW: this.props.fwVersion,
                WorksOrder: this.props.wo
            }
        /* MAC not yet set */
        try {
            const res = await fetch('https://xkghhip63m.execute-api.eu-west-1.amazonaws.com/Prod/v1/status/' + mac, {method: 'POST', headers:{'x-api-key': 's4td5omTka64eu1t3yM411f2BMHEfoXN4joRClBz'}, body: JSON.stringify(testData)});
            const json = await res.json();
            console.log("MAC database: " + JSON.stringify(json))
            if((res.status >= 200) && (res.status < 400))
            {
                failed = false;
            }
        }
        catch (ex)
        {
            console.error(ex);
        }

        await this.setState(ps => {
            ps.tests[testIdx].progress = 100;
            ps.tests[testIdx].success = !failed;
            ps.tests[testIdx].complete = true;
            return ps;
        });
        return !failed;
    }

    async calibrate(testIdx)
    {
        let pass = false;
        try {
            let calibration = await this.sendCommand("calibrate", 30000, (progress)=>
            {
                this.setState(ps => {
                    ps.tests[testIdx].progress = progress;
                    return ps;
                });
            })
            console.log(calibration)
            calibration = JSON.parse(calibration);
            console.log(calibration)
            pass = calibration?.measurements?.reduce((passing, measurement) => passing && measurement.passWithNewCal, true)??false;
            this.setState(ps => {
                ps.tests[testIdx].progress = 100;
                ps.tests[testIdx].success = pass;
                ps.tests[testIdx].complete = true;
                return ps;
            });
        } catch (e) {
            console.log(e)
            this.setState(ps => {
                ps.tests[testIdx].progress = 0;
                ps.tests[testIdx].success = false;
                ps.tests[testIdx].complete = false;
                return ps;
            });
        }
        return pass;
    }

    async sendCommand(command, timeout = 1000, onProgress)
    {
        let port = this.props.port;
        const writer = port.writable.getWriter();
        await writer.write(new TextEncoder().encode(command + "\n\r"));
        writer.releaseLock();
        //const reader = textDecoder.readable.getReader();
        let text = "";
        const reader = port.readable.getReader();
        const decoder = new TextDecoder();
        let interval = undefined;
        let startTime = Date.now();
        try {
            while (true) {
                let remainingTime =  Math.max(0, timeout - (Date.now()-startTime));
                if(text.length > (command.length*2 + 4))
                try {
                    let parseText = text.substring(text.indexOf(command) + command.length).trim().substring(text.indexOf(command) + command.length + 1);
                    JSON.parse(parseText);
                    console.log("JSON Valid")
                    if(interval !== undefined) clearInterval(interval);
                    if(onProgress !== undefined) onProgress(100);
                    await reader.cancel("DONE")
                    return parseText;
                } catch (ignore) {

                }
                let  {value, done} = await Promise.race([
                    reader.read(),
                    new Promise((_, reject) =>
                    {
                        if(onProgress !== undefined)
                        {
                            if(interval !== undefined) clearInterval(interval);
                            interval = setInterval(()=>
                            {
                                onProgress(Math.min(Math.max(((Date.now()-startTime)/timeout)*100),100),0);
                            }, timeout/100);
                        }
                        setTimeout(()=>
                        {
                            reject();
                            if(interval !== undefined) clearInterval(interval);
                        },remainingTime, new Error("timeout"))
                    })
                ]);
                if (done) {
                    // Allow the serial port to be closed later.
                    reader.releaseLock();
                    break;
                }
                if (value) {
                    text += decoder.decode(value);
                }
            }
        } catch (error) {
            /* Catch timeout and cancel reader to trigger lock release */
            await reader.cancel("TIMEOUT")
        }
        text = text.substring(text.indexOf(command) + command.length)
        return text.trim();
    }

    componentDidMount() {
        this.runTests();
    }

    async runTests() {
        await this.props.port.open({baudRate:115200});
        for (const [idx, test] of this.state.tests.entries()) {
            this.setState(ps => {
                ps.currentTest = idx;
                return ps;
            });

            if(await test.action(idx) === false)
            {
                console.log("test '" + this.state.tests[idx].name + "' (idx: " + idx + ") failed, stopping")
                break;
            }
        }

        let pass = this.state.tests.reduce((passing, test) => passing && test.success, true)??false;
        if(this.props.handleSuccess !== undefined)
        {
            this.props.handleSuccess(pass);
        }
    }

    render() {
        return (
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                    <PageTitle sm="4" title={"Testing"} subtitle="Programming"
                               className="text-sm-left"/>
                </Row>
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Tests</h6>
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                <table className="table mb-0">
                                    <thead className="bg-light">
                                    <tr>
                                        <th scope="col" className="border-0">

                                        </th>
                                        <th scope="col" className="border-0">
                                            Test
                                        </th>
                                        <th scope="col" className="border-0">
                                            State
                                        </th>
                                        <th scope="col" className="border-0">
                                            Progress
                                        </th>
                                        <th scope="col" className="border-0">
                                            Pass/Fail
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.tests.map((test, testIdx)=>(<tr key={testIdx}>
                                        <td>{this.state.currentTest===testIdx?"->":""}</td>
                                        <td>{test.name}</td>
                                        <td>{test.progress===0?"Not Started":test.complete?"Done":"Running"}</td>
                                        <td>
                                            <Progress theme="primary" value={test.progress}/></td>
                                        <td>{test.complete?(test.success?"PASS":"FAIL"):"-"}</td>
                                    </tr>))}
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>)
    }
}