import {
    Container,
    Progress,
    Row
} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import React from "react";
import {Storage} from "aws-amplify";

export default class ProgrammingStage extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            macValid: false,
            mac: "",
            prefix: "98:8B:AD:",
            connecting: false,
            flashing: false,
            segments: 0,
            currentSegment: 0,
            segmentProgress: 0,
            segmentWeights: [],
            firmware: undefined
        };

        this.flashFirmware = this.flashFirmware.bind(this);
        this.FlashingProgress = this.FlashingProgress.bind(this);
    }


    componentDidMount() {
        let versions = [];

        Storage.list('ci/') // for listing ALL files without prefix, pass '' instead
            .then(result => {
                result = result.filter(file => null !== file.key.match(/^((\w|\.|-)+\/)+flasher_args.json$/g));
                result.forEach(file => {
                    console.log(file)
                    let parts = file.key.split("/")
                    versions.push({key: file.key, version: parts[1], modified: file.lastModified});
                })
                let downloadResult = Promise.all(versions.map(version=>
                {
                    console.log(version.key)
                    return Storage.get(version.key, {download: true})
                }))
                downloadResult.then(res=>
                {
                    console.log(res)
                    console.log(JSON.stringify(res))
                    console.log(res[res.length-1])
                })
                versions = versions.filter(ver=>ver.version.charAt(0)==='R').sort((a,b)=>(a.version > b.version)?-1:1);
                console.log(versions)
                this.flashFirmware(versions[0]).then(value=>
                {
                    console.log("Flashed OK, next setup");
                }).catch(err=>
                {
                    console.log("Error flashing");
                    console.log(err);
                })
            })
            .catch(err => {
                console.log(err)
            });
    }

    async flashFirmware(fw) {
        let basePath = fw.key.split("/").slice(0, -1).join("/") + "/";
        console.log(basePath)
        let flashArgsObject = await Storage.get(fw.key, {download: true});
        let flashArgsRaw = await flashArgsObject.Body.text();
        let flashArgs = JSON.parse(flashArgsRaw);
        let segmentsToWrite = []
        let flashSuccess = false;
        console.log(flashArgs)
        for (const key of Object.keys(flashArgs.flash_files)) {
            console.log(Number(key) + "->" + flashArgs.flash_files[key])
            let blobObject = await Storage.get(basePath + flashArgs.flash_files[key], {download: true})
            let blob = await (await blobObject.Body).arrayBuffer();
            segmentsToWrite.push({offset: Number(key), buffer: blob})
        }
        let totalSize = segmentsToWrite.reduce((partialSum, segment) => partialSum + segment.buffer.byteLength, 0);
        this.setState(ps => {
            ps.flashing = true;
            ps.segments = segmentsToWrite.length;
            ps.flashingDone = false;
            ps.segmentWeights = segmentsToWrite.map(segment=>segment.buffer.byteLength/totalSize);
            return ps;
        })
        try {
            for(const segment of segmentsToWrite)
            {
                this.setState(ps => {
                    ps.segmentProgress = 0;
                    ps.currentSegment = segmentsToWrite.indexOf(segment);
                    return ps;
                })
                await this.props.stub.flashData(
                    segment.buffer,
                    (bytesWritten) => {
                        this.setState(ps => {
                            ps.segmentProgress = Math.min(bytesWritten / segment.buffer.byteLength, 1)
                            return ps;
                        })
                    },
                    segment.offset
                );
                flashSuccess = true;
            }
        } catch (e) {
            console.log("Flashing failed error:")
            console.log(e)
        }

        /* below is a strange process which leads to the com port being closed,
        this allows the port to be reused with refreshing
        espLoader.disconnect() does not actually close the port but reconfigurePort does but it then tries to reopen it,
        providing an invalid baud rate causes it to close the old port,
        but it is unable to reopen the port due to the invalid baud */

        await this.props.esploader.disconnect();
        if(this.props.port !== undefined) this.props.port.close();

        this.setState(ps => {
            ps.flashing = false;
            ps.segments = 0;
            ps.currentSegment = 0;
            ps.segmentProgress = 0;
            ps.segmentWeights = [];
            return ps;
        })
        console.log("Done")
        if(flashSuccess)
        {
            if(this.props.handleSuccess !== undefined)
            {
                this.props.handleSuccess(fw.version);
            }
        }
        else
        {
            /* TODO: handle failure */
        }
    }


    FlashingProgress = (props) =>
    {
        if(this.state.flashing)
        {
            let progress = 0;
            this.state.segmentWeights.forEach((weight, index)=>
            {
                if(index < this.state.currentSegment)
                {
                    progress += 100*weight;
                }
                else if(index === this.state.currentSegment)
                {
                    progress += 100*weight*this.state.segmentProgress;
                }
            });
            return (
                <div>
                    <Progress theme="primary" value={progress} />
                    <br/>
                    Flashing...
                </div>
            );
        }
        else if(this.state.flashingDone)
        {
            return (
                <div>
                    <Progress theme="primary" value={100}/>
                    <br/>
                    Flashing complete
                </div>
            );
        }
        else
        {
            return <div/>;
        }
    }

    render() {
        return (<Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title={"Flashing firmware"} subtitle="Programming"
                           className="text-sm-left"/>
            </Row>
            <Row>
                <this.FlashingProgress/>
            </Row>
        </Container>)
    }
}