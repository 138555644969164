/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_mobile_analytics_app_id": "1856bbec30f9414faf71b30c8e36bace",
    "aws_mobile_analytics_app_region": "eu-west-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "1856bbec30f9414faf71b30c8e36bace",
            "region": "eu-west-1"
        }
    },
    "Notifications": {
        "Push": {
            "AWSPinpoint": {
                "appId": "1856bbec30f9414faf71b30c8e36bace",
                "region": "eu-west-1"
            }
        },
        "Email": {
            "AWSPinpoint": {
                "appId": "1856bbec30f9414faf71b30c8e36bace",
                "region": "eu-west-1"
            }
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "deviceApi",
            "endpoint": "https://0iy7v1ndf7.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://ijcjhhcf2zgwraff6dqwwin54m.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:fdc5d068-a23e-4304-acdf-b0fd13257c6a",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_XwHCGOtcT",
    "aws_user_pools_web_client_id": "4n89vog2f72atl4dnefih6295i",
    "oauth": {
        "domain": "wapro09217fdb87cb-7fdb87cb-dev.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.wireless-alert.co.uk/",
        "redirectSignOut": "https://www.wireless-alert.co.uk/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "otafu-file-storage-bucket155752-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
