import React from "react";
import {Button, ButtonGroup, Modal, ModalHeader, ModalBody, FormGroup, FormInput, Form, FormSelect} from "shards-react";
import {API} from 'aws-amplify';
import {
    updateProbeSamplePeriod
} from "../../../graphql/mutations";
import moment from "moment";


class SampleActionsMenu extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
      }
  }

    setSampleInterval(interval)
    {
        console.log("Update to be every " + interval + " seconds")

        API.graphql({query: updateProbeSamplePeriod, variables: {id: this.props.device.id, probeIndex: 0, samplePeriod: interval, expectedVersion:this.props.device._version}}).then(value => {
            console.log(value)
            this.props.refreshDevice();
        }).catch(err=>
        {
            alert('Failed to update digest schedule');
        })
    }

  render() {
    return (
      <div>
        <div className="blog-comments__meta text-mutes">
          Probe Sampling
            <div>
                <p className="m-0 my-1 mb-2 text-muted">
                    Interval: {this.renderDurationString(this.props.device.CurrentState.Probes[0].SamplePeriod, true)}
                    {this.props.device.CurrentState.Probes[0].SamplePeriod !== this.props.device.PendingState.Probes[0].SamplePeriod?(" ("+this.renderDurationString(this.props.device.PendingState.Probes[0].SamplePeriod, true)+" Pending)"):""}</p>
                <ButtonGroup size="sm">
                    <Button theme="white" onClick={() => this.setSampleInterval(10)}>
                  <span className="text-success">
                    <i className="material-icons">speed</i>
                  </span>{" "}
                        Normal Mode (10 seconds)
                    </Button>
                    <Button theme="white" onClick={() => this.setSampleInterval(300)}>
                  <span className="text-success">
                    <i className="material-icons">speed</i>
                  </span>{" "}
                        Slow Mode (5 min)
                    </Button>
                </ButtonGroup>
            </div>
        </div>
        <div className="blog-comments__actions">
        </div>
      </div>)
  }

    renderDurationString(duration, excludeZeroValues) {
        let output = "";
        /* duration initially in seconds */
        if (duration > 0) {
            if((false === excludeZeroValues) || ((duration % 60) > 0))
            {
                output = (duration % 60) + "s " + output
            }
            duration = Math.floor(duration / 60);
            /* duration now in minutes */
            if (duration > 0) {
                if((false === excludeZeroValues) || ((duration % 60) > 0))
                {
                    output = (duration % 60) + "m " + output
                }
                duration = Math.floor(duration / 60);
                /* duration now in hours */
                if (duration > 0) {
                    if((false === excludeZeroValues) || ((duration % 24) > 0))
                    {
                        output = (duration % 24) + "h " + output
                    }
                    duration = Math.floor(duration / 24);
                    /* duration now in days */
                    if (duration > 0) {
                        output = duration + "d " + output
                    }
                }
            }
        } else {
            output = "None"
        }
        return output.trim();
    };
}

const SampleActions =
  {
    body: SampleActionsMenu,
    title: "Check In"
  }

export default SampleActions
