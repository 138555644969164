/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDeviceAlt = /* GraphQL */ `
  query GetDeviceAlt($id: ID!) {
    getDeviceAlt(id: $id) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        MACAddress
        Name
        CurrentState {
          CheckInSchedule {
            Type
            Datum
            Period
            CronString
          }
          FirmwareVersion
          Status
          BatteryStatus
          DigestSettings {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          Type
          Probes {
            SN
            Type
            Thermocouple
            EepromId
            SamplePeriod
            Calibrations {
              Timestamp
              Expiry
            }
          }
          Channels {
            Name
            Type
            Resolution
            AlarmSettings {
              Type
              CustomMessage
              CustomExitMessage
              Direction
              Level
              KnockInDelay
              KnockOutDelay
              Hysteresis
            }
            RenderSettings {
              Unit
              ScaleFactor
              Offset
            }
          }
          TrendConfig {
            Period
            Samples
            AggregationMechanism
          }
          PreRollConfig {
            Period
            Samples
            AggregationMechanism
          }
          SetupTime
          LastCommsTime
          LastConfigChangeTime
        }
        PendingState {
          CheckInSchedule {
            Type
            Datum
            Period
            CronString
          }
          FirmwareVersion
          Status
          BatteryStatus
          DigestSettings {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          Type
          Probes {
            SN
            Type
            Thermocouple
            EepromId
            SamplePeriod
            Calibrations {
              Timestamp
              Expiry
            }
          }
          Channels {
            Name
            Type
            Resolution
            AlarmSettings {
              Type
              CustomMessage
              CustomExitMessage
              Direction
              Level
              KnockInDelay
              KnockOutDelay
              Hysteresis
            }
            RenderSettings {
              Unit
              ScaleFactor
              Offset
            }
          }
          TrendConfig {
            Period
            Samples
            AggregationMechanism
          }
          PreRollConfig {
            Period
            Samples
            AggregationMechanism
          }
          SetupTime
          LastCommsTime
          LastConfigChangeTime
        }
        LatestReadings {
          ChannelIdx
          Readings {
            Value
            Min
            Max
            Timestamp
          }
        }
        Events {
          Type
          Source
          Timestamp
          Channel
          AlarmSetting
          Readings {
            Value
            Min
            Max
            Timestamp
          }
          LatestReadings {
            Value
            Min
            Max
            Timestamp
          }
          Processed
        }
        Digests {
          DigestSetting {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          ChannelDigests {
            StartTimestamp
            EndTimestamp
            MinValue
            AvgValue
            MaxValue
            DurationInAlarm
            AlarmsEntered
            AlarmsExited
          }
          DeviceDigest {
            StartTimestamp
            EndTimestamp
            Uptime
            HeapFreeLWM
            BatteryConsumed
          }
          TimeSubmitted
        }
        Spectators
        Maintainers
        Tags
        EmailSettings {
          DigestEnabled
          EventsEnabled
          PushEnabled
          CustomBranding
        }
        owner
        Management {
          DisableUpdates
        }
        Groups {
          items {
            id
            deviceID
            groupID
            device {
              id
              MACAddress
              Name
              CurrentState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              PendingState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              LatestReadings {
                ChannelIdx
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
              }
              Events {
                Type
                Source
                Timestamp
                Channel
                AlarmSetting
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                LatestReadings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                Processed
              }
              Digests {
                DigestSetting {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                ChannelDigests {
                  StartTimestamp
                  EndTimestamp
                  MinValue
                  AvgValue
                  MaxValue
                  DurationInAlarm
                  AlarmsEntered
                  AlarmsExited
                }
                DeviceDigest {
                  StartTimestamp
                  EndTimestamp
                  Uptime
                  HeapFreeLWM
                  BatteryConsumed
                }
                TimeSubmitted
              }
              Spectators
              Maintainers
              Tags
              EmailSettings {
                DigestEnabled
                EventsEnabled
                PushEnabled
                CustomBranding
              }
              owner
              Management {
                DisableUpdates
              }
              Groups {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            group {
              id
              Name
              Spectators
              Maintainers
              owner
              Devices {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            Spectators
            Maintainers
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDevices = /* GraphQL */ `
  query SyncDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        MACAddress
        Name
        CurrentState {
          CheckInSchedule {
            Type
            Datum
            Period
            CronString
          }
          FirmwareVersion
          Status
          BatteryStatus
          DigestSettings {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          Type
          Probes {
            SN
            Type
            Thermocouple
            EepromId
            SamplePeriod
            Calibrations {
              Timestamp
              Expiry
            }
          }
          Channels {
            Name
            Type
            Resolution
            AlarmSettings {
              Type
              CustomMessage
              CustomExitMessage
              Direction
              Level
              KnockInDelay
              KnockOutDelay
              Hysteresis
            }
            RenderSettings {
              Unit
              ScaleFactor
              Offset
            }
          }
          TrendConfig {
            Period
            Samples
            AggregationMechanism
          }
          PreRollConfig {
            Period
            Samples
            AggregationMechanism
          }
          SetupTime
          LastCommsTime
          LastConfigChangeTime
        }
        PendingState {
          CheckInSchedule {
            Type
            Datum
            Period
            CronString
          }
          FirmwareVersion
          Status
          BatteryStatus
          DigestSettings {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          Type
          Probes {
            SN
            Type
            Thermocouple
            EepromId
            SamplePeriod
            Calibrations {
              Timestamp
              Expiry
            }
          }
          Channels {
            Name
            Type
            Resolution
            AlarmSettings {
              Type
              CustomMessage
              CustomExitMessage
              Direction
              Level
              KnockInDelay
              KnockOutDelay
              Hysteresis
            }
            RenderSettings {
              Unit
              ScaleFactor
              Offset
            }
          }
          TrendConfig {
            Period
            Samples
            AggregationMechanism
          }
          PreRollConfig {
            Period
            Samples
            AggregationMechanism
          }
          SetupTime
          LastCommsTime
          LastConfigChangeTime
        }
        LatestReadings {
          ChannelIdx
          Readings {
            Value
            Min
            Max
            Timestamp
          }
        }
        Events {
          Type
          Source
          Timestamp
          Channel
          AlarmSetting
          Readings {
            Value
            Min
            Max
            Timestamp
          }
          LatestReadings {
            Value
            Min
            Max
            Timestamp
          }
          Processed
        }
        Digests {
          DigestSetting {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          ChannelDigests {
            StartTimestamp
            EndTimestamp
            MinValue
            AvgValue
            MaxValue
            DurationInAlarm
            AlarmsEntered
            AlarmsExited
          }
          DeviceDigest {
            StartTimestamp
            EndTimestamp
            Uptime
            HeapFreeLWM
            BatteryConsumed
          }
          TimeSubmitted
        }
        Spectators
        Maintainers
        Tags
        EmailSettings {
          DigestEnabled
          EventsEnabled
          PushEnabled
          CustomBranding
        }
        owner
        Management {
          DisableUpdates
        }
        Groups {
          items {
            id
            deviceID
            groupID
            device {
              id
              MACAddress
              Name
              CurrentState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              PendingState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              LatestReadings {
                ChannelIdx
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
              }
              Events {
                Type
                Source
                Timestamp
                Channel
                AlarmSetting
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                LatestReadings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                Processed
              }
              Digests {
                DigestSetting {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                ChannelDigests {
                  StartTimestamp
                  EndTimestamp
                  MinValue
                  AvgValue
                  MaxValue
                  DurationInAlarm
                  AlarmsEntered
                  AlarmsExited
                }
                DeviceDigest {
                  StartTimestamp
                  EndTimestamp
                  Uptime
                  HeapFreeLWM
                  BatteryConsumed
                }
                TimeSubmitted
              }
              Spectators
              Maintainers
              Tags
              EmailSettings {
                DigestEnabled
                EventsEnabled
                PushEnabled
                CustomBranding
              }
              owner
              Management {
                DisableUpdates
              }
              Groups {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            group {
              id
              Name
              Spectators
              Maintainers
              owner
              Devices {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            Spectators
            Maintainers
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      Name
      Spectators
      Maintainers
      owner
      Devices {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Spectators
        Maintainers
        owner
        Devices {
          items {
            id
            deviceID
            groupID
            device {
              id
              MACAddress
              Name
              CurrentState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              PendingState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              LatestReadings {
                ChannelIdx
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
              }
              Events {
                Type
                Source
                Timestamp
                Channel
                AlarmSetting
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                LatestReadings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                Processed
              }
              Digests {
                DigestSetting {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                ChannelDigests {
                  StartTimestamp
                  EndTimestamp
                  MinValue
                  AvgValue
                  MaxValue
                  DurationInAlarm
                  AlarmsEntered
                  AlarmsExited
                }
                DeviceDigest {
                  StartTimestamp
                  EndTimestamp
                  Uptime
                  HeapFreeLWM
                  BatteryConsumed
                }
                TimeSubmitted
              }
              Spectators
              Maintainers
              Tags
              EmailSettings {
                DigestEnabled
                EventsEnabled
                PushEnabled
                CustomBranding
              }
              owner
              Management {
                DisableUpdates
              }
              Groups {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            group {
              id
              Name
              Spectators
              Maintainers
              owner
              Devices {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            Spectators
            Maintainers
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGroups = /* GraphQL */ `
  query SyncGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Name
        Spectators
        Maintainers
        owner
        Devices {
          items {
            id
            deviceID
            groupID
            device {
              id
              MACAddress
              Name
              CurrentState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              PendingState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              LatestReadings {
                ChannelIdx
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
              }
              Events {
                Type
                Source
                Timestamp
                Channel
                AlarmSetting
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                LatestReadings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                Processed
              }
              Digests {
                DigestSetting {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                ChannelDigests {
                  StartTimestamp
                  EndTimestamp
                  MinValue
                  AvgValue
                  MaxValue
                  DurationInAlarm
                  AlarmsEntered
                  AlarmsExited
                }
                DeviceDigest {
                  StartTimestamp
                  EndTimestamp
                  Uptime
                  HeapFreeLWM
                  BatteryConsumed
                }
                TimeSubmitted
              }
              Spectators
              Maintainers
              Tags
              EmailSettings {
                DigestEnabled
                EventsEnabled
                PushEnabled
                CustomBranding
              }
              owner
              Management {
                DisableUpdates
              }
              Groups {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            group {
              id
              Name
              Spectators
              Maintainers
              owner
              Devices {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            Spectators
            Maintainers
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDeviceGroups = /* GraphQL */ `
  query GetDeviceGroups($id: ID!) {
    getDeviceGroups(id: $id) {
      id
      deviceID
      groupID
      device {
        id
        MACAddress
        Name
        CurrentState {
          CheckInSchedule {
            Type
            Datum
            Period
            CronString
          }
          FirmwareVersion
          Status
          BatteryStatus
          DigestSettings {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          Type
          Probes {
            SN
            Type
            Thermocouple
            EepromId
            SamplePeriod
            Calibrations {
              Timestamp
              Expiry
            }
          }
          Channels {
            Name
            Type
            Resolution
            AlarmSettings {
              Type
              CustomMessage
              CustomExitMessage
              Direction
              Level
              KnockInDelay
              KnockOutDelay
              Hysteresis
            }
            RenderSettings {
              Unit
              ScaleFactor
              Offset
            }
          }
          TrendConfig {
            Period
            Samples
            AggregationMechanism
          }
          PreRollConfig {
            Period
            Samples
            AggregationMechanism
          }
          SetupTime
          LastCommsTime
          LastConfigChangeTime
        }
        PendingState {
          CheckInSchedule {
            Type
            Datum
            Period
            CronString
          }
          FirmwareVersion
          Status
          BatteryStatus
          DigestSettings {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          Type
          Probes {
            SN
            Type
            Thermocouple
            EepromId
            SamplePeriod
            Calibrations {
              Timestamp
              Expiry
            }
          }
          Channels {
            Name
            Type
            Resolution
            AlarmSettings {
              Type
              CustomMessage
              CustomExitMessage
              Direction
              Level
              KnockInDelay
              KnockOutDelay
              Hysteresis
            }
            RenderSettings {
              Unit
              ScaleFactor
              Offset
            }
          }
          TrendConfig {
            Period
            Samples
            AggregationMechanism
          }
          PreRollConfig {
            Period
            Samples
            AggregationMechanism
          }
          SetupTime
          LastCommsTime
          LastConfigChangeTime
        }
        LatestReadings {
          ChannelIdx
          Readings {
            Value
            Min
            Max
            Timestamp
          }
        }
        Events {
          Type
          Source
          Timestamp
          Channel
          AlarmSetting
          Readings {
            Value
            Min
            Max
            Timestamp
          }
          LatestReadings {
            Value
            Min
            Max
            Timestamp
          }
          Processed
        }
        Digests {
          DigestSetting {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          ChannelDigests {
            StartTimestamp
            EndTimestamp
            MinValue
            AvgValue
            MaxValue
            DurationInAlarm
            AlarmsEntered
            AlarmsExited
          }
          DeviceDigest {
            StartTimestamp
            EndTimestamp
            Uptime
            HeapFreeLWM
            BatteryConsumed
          }
          TimeSubmitted
        }
        Spectators
        Maintainers
        Tags
        EmailSettings {
          DigestEnabled
          EventsEnabled
          PushEnabled
          CustomBranding
        }
        owner
        Management {
          DisableUpdates
        }
        Groups {
          items {
            id
            deviceID
            groupID
            device {
              id
              MACAddress
              Name
              CurrentState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              PendingState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              LatestReadings {
                ChannelIdx
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
              }
              Events {
                Type
                Source
                Timestamp
                Channel
                AlarmSetting
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                LatestReadings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                Processed
              }
              Digests {
                DigestSetting {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                ChannelDigests {
                  StartTimestamp
                  EndTimestamp
                  MinValue
                  AvgValue
                  MaxValue
                  DurationInAlarm
                  AlarmsEntered
                  AlarmsExited
                }
                DeviceDigest {
                  StartTimestamp
                  EndTimestamp
                  Uptime
                  HeapFreeLWM
                  BatteryConsumed
                }
                TimeSubmitted
              }
              Spectators
              Maintainers
              Tags
              EmailSettings {
                DigestEnabled
                EventsEnabled
                PushEnabled
                CustomBranding
              }
              owner
              Management {
                DisableUpdates
              }
              Groups {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            group {
              id
              Name
              Spectators
              Maintainers
              owner
              Devices {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            Spectators
            Maintainers
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      group {
        id
        Name
        Spectators
        Maintainers
        owner
        Devices {
          items {
            id
            deviceID
            groupID
            device {
              id
              MACAddress
              Name
              CurrentState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              PendingState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              LatestReadings {
                ChannelIdx
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
              }
              Events {
                Type
                Source
                Timestamp
                Channel
                AlarmSetting
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                LatestReadings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                Processed
              }
              Digests {
                DigestSetting {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                ChannelDigests {
                  StartTimestamp
                  EndTimestamp
                  MinValue
                  AvgValue
                  MaxValue
                  DurationInAlarm
                  AlarmsEntered
                  AlarmsExited
                }
                DeviceDigest {
                  StartTimestamp
                  EndTimestamp
                  Uptime
                  HeapFreeLWM
                  BatteryConsumed
                }
                TimeSubmitted
              }
              Spectators
              Maintainers
              Tags
              EmailSettings {
                DigestEnabled
                EventsEnabled
                PushEnabled
                CustomBranding
              }
              owner
              Management {
                DisableUpdates
              }
              Groups {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            group {
              id
              Name
              Spectators
              Maintainers
              owner
              Devices {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            Spectators
            Maintainers
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      Spectators
      Maintainers
    }
  }
`;
export const listDeviceGroups = /* GraphQL */ `
  query ListDeviceGroups(
    $filter: ModelDeviceGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deviceID
        groupID
        device {
          id
          MACAddress
          Name
          CurrentState {
            CheckInSchedule {
              Type
              Datum
              Period
              CronString
            }
            FirmwareVersion
            Status
            BatteryStatus
            DigestSettings {
              Type
              Channel
              Schedule {
                Type
                Datum
                Period
                CronString
              }
              Receivers
            }
            Type
            Probes {
              SN
              Type
              Thermocouple
              EepromId
              SamplePeriod
              Calibrations {
                Timestamp
                Expiry
              }
            }
            Channels {
              Name
              Type
              Resolution
              AlarmSettings {
                Type
                CustomMessage
                CustomExitMessage
                Direction
                Level
                KnockInDelay
                KnockOutDelay
                Hysteresis
              }
              RenderSettings {
                Unit
                ScaleFactor
                Offset
              }
            }
            TrendConfig {
              Period
              Samples
              AggregationMechanism
            }
            PreRollConfig {
              Period
              Samples
              AggregationMechanism
            }
            SetupTime
            LastCommsTime
            LastConfigChangeTime
          }
          PendingState {
            CheckInSchedule {
              Type
              Datum
              Period
              CronString
            }
            FirmwareVersion
            Status
            BatteryStatus
            DigestSettings {
              Type
              Channel
              Schedule {
                Type
                Datum
                Period
                CronString
              }
              Receivers
            }
            Type
            Probes {
              SN
              Type
              Thermocouple
              EepromId
              SamplePeriod
              Calibrations {
                Timestamp
                Expiry
              }
            }
            Channels {
              Name
              Type
              Resolution
              AlarmSettings {
                Type
                CustomMessage
                CustomExitMessage
                Direction
                Level
                KnockInDelay
                KnockOutDelay
                Hysteresis
              }
              RenderSettings {
                Unit
                ScaleFactor
                Offset
              }
            }
            TrendConfig {
              Period
              Samples
              AggregationMechanism
            }
            PreRollConfig {
              Period
              Samples
              AggregationMechanism
            }
            SetupTime
            LastCommsTime
            LastConfigChangeTime
          }
          LatestReadings {
            ChannelIdx
            Readings {
              Value
              Min
              Max
              Timestamp
            }
          }
          Events {
            Type
            Source
            Timestamp
            Channel
            AlarmSetting
            Readings {
              Value
              Min
              Max
              Timestamp
            }
            LatestReadings {
              Value
              Min
              Max
              Timestamp
            }
            Processed
          }
          Digests {
            DigestSetting {
              Type
              Channel
              Schedule {
                Type
                Datum
                Period
                CronString
              }
              Receivers
            }
            ChannelDigests {
              StartTimestamp
              EndTimestamp
              MinValue
              AvgValue
              MaxValue
              DurationInAlarm
              AlarmsEntered
              AlarmsExited
            }
            DeviceDigest {
              StartTimestamp
              EndTimestamp
              Uptime
              HeapFreeLWM
              BatteryConsumed
            }
            TimeSubmitted
          }
          Spectators
          Maintainers
          Tags
          EmailSettings {
            DigestEnabled
            EventsEnabled
            PushEnabled
            CustomBranding
          }
          owner
          Management {
            DisableUpdates
          }
          Groups {
            items {
              id
              deviceID
              groupID
              device {
                id
                MACAddress
                Name
                CurrentState {
                  CheckInSchedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  FirmwareVersion
                  Status
                  BatteryStatus
                  DigestSettings {
                    Type
                    Channel
                    Schedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    Receivers
                  }
                  Type
                  Probes {
                    SN
                    Type
                    Thermocouple
                    EepromId
                    SamplePeriod
                    Calibrations {
                      Timestamp
                      Expiry
                    }
                  }
                  Channels {
                    Name
                    Type
                    Resolution
                    AlarmSettings {
                      Type
                      CustomMessage
                      CustomExitMessage
                      Direction
                      Level
                      KnockInDelay
                      KnockOutDelay
                      Hysteresis
                    }
                    RenderSettings {
                      Unit
                      ScaleFactor
                      Offset
                    }
                  }
                  TrendConfig {
                    Period
                    Samples
                    AggregationMechanism
                  }
                  PreRollConfig {
                    Period
                    Samples
                    AggregationMechanism
                  }
                  SetupTime
                  LastCommsTime
                  LastConfigChangeTime
                }
                PendingState {
                  CheckInSchedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  FirmwareVersion
                  Status
                  BatteryStatus
                  DigestSettings {
                    Type
                    Channel
                    Schedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    Receivers
                  }
                  Type
                  Probes {
                    SN
                    Type
                    Thermocouple
                    EepromId
                    SamplePeriod
                    Calibrations {
                      Timestamp
                      Expiry
                    }
                  }
                  Channels {
                    Name
                    Type
                    Resolution
                    AlarmSettings {
                      Type
                      CustomMessage
                      CustomExitMessage
                      Direction
                      Level
                      KnockInDelay
                      KnockOutDelay
                      Hysteresis
                    }
                    RenderSettings {
                      Unit
                      ScaleFactor
                      Offset
                    }
                  }
                  TrendConfig {
                    Period
                    Samples
                    AggregationMechanism
                  }
                  PreRollConfig {
                    Period
                    Samples
                    AggregationMechanism
                  }
                  SetupTime
                  LastCommsTime
                  LastConfigChangeTime
                }
                LatestReadings {
                  ChannelIdx
                  Readings {
                    Value
                    Min
                    Max
                    Timestamp
                  }
                }
                Events {
                  Type
                  Source
                  Timestamp
                  Channel
                  AlarmSetting
                  Readings {
                    Value
                    Min
                    Max
                    Timestamp
                  }
                  LatestReadings {
                    Value
                    Min
                    Max
                    Timestamp
                  }
                  Processed
                }
                Digests {
                  DigestSetting {
                    Type
                    Channel
                    Schedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    Receivers
                  }
                  ChannelDigests {
                    StartTimestamp
                    EndTimestamp
                    MinValue
                    AvgValue
                    MaxValue
                    DurationInAlarm
                    AlarmsEntered
                    AlarmsExited
                  }
                  DeviceDigest {
                    StartTimestamp
                    EndTimestamp
                    Uptime
                    HeapFreeLWM
                    BatteryConsumed
                  }
                  TimeSubmitted
                }
                Spectators
                Maintainers
                Tags
                EmailSettings {
                  DigestEnabled
                  EventsEnabled
                  PushEnabled
                  CustomBranding
                }
                owner
                Management {
                  DisableUpdates
                }
                Groups {
                  items {
                    id
                    deviceID
                    groupID
                    device {
                      id
                      MACAddress
                      Name
                      CurrentState {
                        FirmwareVersion
                        Status
                        BatteryStatus
                        Type
                        SetupTime
                        LastCommsTime
                        LastConfigChangeTime
                      }
                      PendingState {
                        FirmwareVersion
                        Status
                        BatteryStatus
                        Type
                        SetupTime
                        LastCommsTime
                        LastConfigChangeTime
                      }
                      LatestReadings {
                        ChannelIdx
                      }
                      Events {
                        Type
                        Source
                        Timestamp
                        Channel
                        AlarmSetting
                        Processed
                      }
                      Digests {
                        TimeSubmitted
                      }
                      Spectators
                      Maintainers
                      Tags
                      EmailSettings {
                        DigestEnabled
                        EventsEnabled
                        PushEnabled
                        CustomBranding
                      }
                      owner
                      Management {
                        DisableUpdates
                      }
                      Groups {
                        nextToken
                        startedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    group {
                      id
                      Name
                      Spectators
                      Maintainers
                      owner
                      Devices {
                        nextToken
                        startedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    owner
                    Spectators
                    Maintainers
                  }
                  nextToken
                  startedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              group {
                id
                Name
                Spectators
                Maintainers
                owner
                Devices {
                  items {
                    id
                    deviceID
                    groupID
                    device {
                      id
                      MACAddress
                      Name
                      CurrentState {
                        FirmwareVersion
                        Status
                        BatteryStatus
                        Type
                        SetupTime
                        LastCommsTime
                        LastConfigChangeTime
                      }
                      PendingState {
                        FirmwareVersion
                        Status
                        BatteryStatus
                        Type
                        SetupTime
                        LastCommsTime
                        LastConfigChangeTime
                      }
                      LatestReadings {
                        ChannelIdx
                      }
                      Events {
                        Type
                        Source
                        Timestamp
                        Channel
                        AlarmSetting
                        Processed
                      }
                      Digests {
                        TimeSubmitted
                      }
                      Spectators
                      Maintainers
                      Tags
                      EmailSettings {
                        DigestEnabled
                        EventsEnabled
                        PushEnabled
                        CustomBranding
                      }
                      owner
                      Management {
                        DisableUpdates
                      }
                      Groups {
                        nextToken
                        startedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    group {
                      id
                      Name
                      Spectators
                      Maintainers
                      owner
                      Devices {
                        nextToken
                        startedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    owner
                    Spectators
                    Maintainers
                  }
                  nextToken
                  startedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              Spectators
              Maintainers
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          Name
          Spectators
          Maintainers
          owner
          Devices {
            items {
              id
              deviceID
              groupID
              device {
                id
                MACAddress
                Name
                CurrentState {
                  CheckInSchedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  FirmwareVersion
                  Status
                  BatteryStatus
                  DigestSettings {
                    Type
                    Channel
                    Schedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    Receivers
                  }
                  Type
                  Probes {
                    SN
                    Type
                    Thermocouple
                    EepromId
                    SamplePeriod
                    Calibrations {
                      Timestamp
                      Expiry
                    }
                  }
                  Channels {
                    Name
                    Type
                    Resolution
                    AlarmSettings {
                      Type
                      CustomMessage
                      CustomExitMessage
                      Direction
                      Level
                      KnockInDelay
                      KnockOutDelay
                      Hysteresis
                    }
                    RenderSettings {
                      Unit
                      ScaleFactor
                      Offset
                    }
                  }
                  TrendConfig {
                    Period
                    Samples
                    AggregationMechanism
                  }
                  PreRollConfig {
                    Period
                    Samples
                    AggregationMechanism
                  }
                  SetupTime
                  LastCommsTime
                  LastConfigChangeTime
                }
                PendingState {
                  CheckInSchedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  FirmwareVersion
                  Status
                  BatteryStatus
                  DigestSettings {
                    Type
                    Channel
                    Schedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    Receivers
                  }
                  Type
                  Probes {
                    SN
                    Type
                    Thermocouple
                    EepromId
                    SamplePeriod
                    Calibrations {
                      Timestamp
                      Expiry
                    }
                  }
                  Channels {
                    Name
                    Type
                    Resolution
                    AlarmSettings {
                      Type
                      CustomMessage
                      CustomExitMessage
                      Direction
                      Level
                      KnockInDelay
                      KnockOutDelay
                      Hysteresis
                    }
                    RenderSettings {
                      Unit
                      ScaleFactor
                      Offset
                    }
                  }
                  TrendConfig {
                    Period
                    Samples
                    AggregationMechanism
                  }
                  PreRollConfig {
                    Period
                    Samples
                    AggregationMechanism
                  }
                  SetupTime
                  LastCommsTime
                  LastConfigChangeTime
                }
                LatestReadings {
                  ChannelIdx
                  Readings {
                    Value
                    Min
                    Max
                    Timestamp
                  }
                }
                Events {
                  Type
                  Source
                  Timestamp
                  Channel
                  AlarmSetting
                  Readings {
                    Value
                    Min
                    Max
                    Timestamp
                  }
                  LatestReadings {
                    Value
                    Min
                    Max
                    Timestamp
                  }
                  Processed
                }
                Digests {
                  DigestSetting {
                    Type
                    Channel
                    Schedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    Receivers
                  }
                  ChannelDigests {
                    StartTimestamp
                    EndTimestamp
                    MinValue
                    AvgValue
                    MaxValue
                    DurationInAlarm
                    AlarmsEntered
                    AlarmsExited
                  }
                  DeviceDigest {
                    StartTimestamp
                    EndTimestamp
                    Uptime
                    HeapFreeLWM
                    BatteryConsumed
                  }
                  TimeSubmitted
                }
                Spectators
                Maintainers
                Tags
                EmailSettings {
                  DigestEnabled
                  EventsEnabled
                  PushEnabled
                  CustomBranding
                }
                owner
                Management {
                  DisableUpdates
                }
                Groups {
                  items {
                    id
                    deviceID
                    groupID
                    device {
                      id
                      MACAddress
                      Name
                      CurrentState {
                        FirmwareVersion
                        Status
                        BatteryStatus
                        Type
                        SetupTime
                        LastCommsTime
                        LastConfigChangeTime
                      }
                      PendingState {
                        FirmwareVersion
                        Status
                        BatteryStatus
                        Type
                        SetupTime
                        LastCommsTime
                        LastConfigChangeTime
                      }
                      LatestReadings {
                        ChannelIdx
                      }
                      Events {
                        Type
                        Source
                        Timestamp
                        Channel
                        AlarmSetting
                        Processed
                      }
                      Digests {
                        TimeSubmitted
                      }
                      Spectators
                      Maintainers
                      Tags
                      EmailSettings {
                        DigestEnabled
                        EventsEnabled
                        PushEnabled
                        CustomBranding
                      }
                      owner
                      Management {
                        DisableUpdates
                      }
                      Groups {
                        nextToken
                        startedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    group {
                      id
                      Name
                      Spectators
                      Maintainers
                      owner
                      Devices {
                        nextToken
                        startedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    owner
                    Spectators
                    Maintainers
                  }
                  nextToken
                  startedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              group {
                id
                Name
                Spectators
                Maintainers
                owner
                Devices {
                  items {
                    id
                    deviceID
                    groupID
                    device {
                      id
                      MACAddress
                      Name
                      CurrentState {
                        FirmwareVersion
                        Status
                        BatteryStatus
                        Type
                        SetupTime
                        LastCommsTime
                        LastConfigChangeTime
                      }
                      PendingState {
                        FirmwareVersion
                        Status
                        BatteryStatus
                        Type
                        SetupTime
                        LastCommsTime
                        LastConfigChangeTime
                      }
                      LatestReadings {
                        ChannelIdx
                      }
                      Events {
                        Type
                        Source
                        Timestamp
                        Channel
                        AlarmSetting
                        Processed
                      }
                      Digests {
                        TimeSubmitted
                      }
                      Spectators
                      Maintainers
                      Tags
                      EmailSettings {
                        DigestEnabled
                        EventsEnabled
                        PushEnabled
                        CustomBranding
                      }
                      owner
                      Management {
                        DisableUpdates
                      }
                      Groups {
                        nextToken
                        startedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    group {
                      id
                      Name
                      Spectators
                      Maintainers
                      owner
                      Devices {
                        nextToken
                        startedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    owner
                    Spectators
                    Maintainers
                  }
                  nextToken
                  startedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              Spectators
              Maintainers
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        Spectators
        Maintainers
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDeviceGroups = /* GraphQL */ `
  query SyncDeviceGroups(
    $filter: ModelDeviceGroupsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeviceGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        deviceID
        groupID
        device {
          id
          MACAddress
          Name
          CurrentState {
            CheckInSchedule {
              Type
              Datum
              Period
              CronString
            }
            FirmwareVersion
            Status
            BatteryStatus
            DigestSettings {
              Type
              Channel
              Schedule {
                Type
                Datum
                Period
                CronString
              }
              Receivers
            }
            Type
            Probes {
              SN
              Type
              Thermocouple
              EepromId
              SamplePeriod
              Calibrations {
                Timestamp
                Expiry
              }
            }
            Channels {
              Name
              Type
              Resolution
              AlarmSettings {
                Type
                CustomMessage
                CustomExitMessage
                Direction
                Level
                KnockInDelay
                KnockOutDelay
                Hysteresis
              }
              RenderSettings {
                Unit
                ScaleFactor
                Offset
              }
            }
            TrendConfig {
              Period
              Samples
              AggregationMechanism
            }
            PreRollConfig {
              Period
              Samples
              AggregationMechanism
            }
            SetupTime
            LastCommsTime
            LastConfigChangeTime
          }
          PendingState {
            CheckInSchedule {
              Type
              Datum
              Period
              CronString
            }
            FirmwareVersion
            Status
            BatteryStatus
            DigestSettings {
              Type
              Channel
              Schedule {
                Type
                Datum
                Period
                CronString
              }
              Receivers
            }
            Type
            Probes {
              SN
              Type
              Thermocouple
              EepromId
              SamplePeriod
              Calibrations {
                Timestamp
                Expiry
              }
            }
            Channels {
              Name
              Type
              Resolution
              AlarmSettings {
                Type
                CustomMessage
                CustomExitMessage
                Direction
                Level
                KnockInDelay
                KnockOutDelay
                Hysteresis
              }
              RenderSettings {
                Unit
                ScaleFactor
                Offset
              }
            }
            TrendConfig {
              Period
              Samples
              AggregationMechanism
            }
            PreRollConfig {
              Period
              Samples
              AggregationMechanism
            }
            SetupTime
            LastCommsTime
            LastConfigChangeTime
          }
          LatestReadings {
            ChannelIdx
            Readings {
              Value
              Min
              Max
              Timestamp
            }
          }
          Events {
            Type
            Source
            Timestamp
            Channel
            AlarmSetting
            Readings {
              Value
              Min
              Max
              Timestamp
            }
            LatestReadings {
              Value
              Min
              Max
              Timestamp
            }
            Processed
          }
          Digests {
            DigestSetting {
              Type
              Channel
              Schedule {
                Type
                Datum
                Period
                CronString
              }
              Receivers
            }
            ChannelDigests {
              StartTimestamp
              EndTimestamp
              MinValue
              AvgValue
              MaxValue
              DurationInAlarm
              AlarmsEntered
              AlarmsExited
            }
            DeviceDigest {
              StartTimestamp
              EndTimestamp
              Uptime
              HeapFreeLWM
              BatteryConsumed
            }
            TimeSubmitted
          }
          Spectators
          Maintainers
          Tags
          EmailSettings {
            DigestEnabled
            EventsEnabled
            PushEnabled
            CustomBranding
          }
          owner
          Management {
            DisableUpdates
          }
          Groups {
            items {
              id
              deviceID
              groupID
              device {
                id
                MACAddress
                Name
                CurrentState {
                  CheckInSchedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  FirmwareVersion
                  Status
                  BatteryStatus
                  DigestSettings {
                    Type
                    Channel
                    Schedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    Receivers
                  }
                  Type
                  Probes {
                    SN
                    Type
                    Thermocouple
                    EepromId
                    SamplePeriod
                    Calibrations {
                      Timestamp
                      Expiry
                    }
                  }
                  Channels {
                    Name
                    Type
                    Resolution
                    AlarmSettings {
                      Type
                      CustomMessage
                      CustomExitMessage
                      Direction
                      Level
                      KnockInDelay
                      KnockOutDelay
                      Hysteresis
                    }
                    RenderSettings {
                      Unit
                      ScaleFactor
                      Offset
                    }
                  }
                  TrendConfig {
                    Period
                    Samples
                    AggregationMechanism
                  }
                  PreRollConfig {
                    Period
                    Samples
                    AggregationMechanism
                  }
                  SetupTime
                  LastCommsTime
                  LastConfigChangeTime
                }
                PendingState {
                  CheckInSchedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  FirmwareVersion
                  Status
                  BatteryStatus
                  DigestSettings {
                    Type
                    Channel
                    Schedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    Receivers
                  }
                  Type
                  Probes {
                    SN
                    Type
                    Thermocouple
                    EepromId
                    SamplePeriod
                    Calibrations {
                      Timestamp
                      Expiry
                    }
                  }
                  Channels {
                    Name
                    Type
                    Resolution
                    AlarmSettings {
                      Type
                      CustomMessage
                      CustomExitMessage
                      Direction
                      Level
                      KnockInDelay
                      KnockOutDelay
                      Hysteresis
                    }
                    RenderSettings {
                      Unit
                      ScaleFactor
                      Offset
                    }
                  }
                  TrendConfig {
                    Period
                    Samples
                    AggregationMechanism
                  }
                  PreRollConfig {
                    Period
                    Samples
                    AggregationMechanism
                  }
                  SetupTime
                  LastCommsTime
                  LastConfigChangeTime
                }
                LatestReadings {
                  ChannelIdx
                  Readings {
                    Value
                    Min
                    Max
                    Timestamp
                  }
                }
                Events {
                  Type
                  Source
                  Timestamp
                  Channel
                  AlarmSetting
                  Readings {
                    Value
                    Min
                    Max
                    Timestamp
                  }
                  LatestReadings {
                    Value
                    Min
                    Max
                    Timestamp
                  }
                  Processed
                }
                Digests {
                  DigestSetting {
                    Type
                    Channel
                    Schedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    Receivers
                  }
                  ChannelDigests {
                    StartTimestamp
                    EndTimestamp
                    MinValue
                    AvgValue
                    MaxValue
                    DurationInAlarm
                    AlarmsEntered
                    AlarmsExited
                  }
                  DeviceDigest {
                    StartTimestamp
                    EndTimestamp
                    Uptime
                    HeapFreeLWM
                    BatteryConsumed
                  }
                  TimeSubmitted
                }
                Spectators
                Maintainers
                Tags
                EmailSettings {
                  DigestEnabled
                  EventsEnabled
                  PushEnabled
                  CustomBranding
                }
                owner
                Management {
                  DisableUpdates
                }
                Groups {
                  items {
                    id
                    deviceID
                    groupID
                    device {
                      id
                      MACAddress
                      Name
                      CurrentState {
                        FirmwareVersion
                        Status
                        BatteryStatus
                        Type
                        SetupTime
                        LastCommsTime
                        LastConfigChangeTime
                      }
                      PendingState {
                        FirmwareVersion
                        Status
                        BatteryStatus
                        Type
                        SetupTime
                        LastCommsTime
                        LastConfigChangeTime
                      }
                      LatestReadings {
                        ChannelIdx
                      }
                      Events {
                        Type
                        Source
                        Timestamp
                        Channel
                        AlarmSetting
                        Processed
                      }
                      Digests {
                        TimeSubmitted
                      }
                      Spectators
                      Maintainers
                      Tags
                      EmailSettings {
                        DigestEnabled
                        EventsEnabled
                        PushEnabled
                        CustomBranding
                      }
                      owner
                      Management {
                        DisableUpdates
                      }
                      Groups {
                        nextToken
                        startedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    group {
                      id
                      Name
                      Spectators
                      Maintainers
                      owner
                      Devices {
                        nextToken
                        startedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    owner
                    Spectators
                    Maintainers
                  }
                  nextToken
                  startedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              group {
                id
                Name
                Spectators
                Maintainers
                owner
                Devices {
                  items {
                    id
                    deviceID
                    groupID
                    device {
                      id
                      MACAddress
                      Name
                      CurrentState {
                        FirmwareVersion
                        Status
                        BatteryStatus
                        Type
                        SetupTime
                        LastCommsTime
                        LastConfigChangeTime
                      }
                      PendingState {
                        FirmwareVersion
                        Status
                        BatteryStatus
                        Type
                        SetupTime
                        LastCommsTime
                        LastConfigChangeTime
                      }
                      LatestReadings {
                        ChannelIdx
                      }
                      Events {
                        Type
                        Source
                        Timestamp
                        Channel
                        AlarmSetting
                        Processed
                      }
                      Digests {
                        TimeSubmitted
                      }
                      Spectators
                      Maintainers
                      Tags
                      EmailSettings {
                        DigestEnabled
                        EventsEnabled
                        PushEnabled
                        CustomBranding
                      }
                      owner
                      Management {
                        DisableUpdates
                      }
                      Groups {
                        nextToken
                        startedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    group {
                      id
                      Name
                      Spectators
                      Maintainers
                      owner
                      Devices {
                        nextToken
                        startedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    owner
                    Spectators
                    Maintainers
                  }
                  nextToken
                  startedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              Spectators
              Maintainers
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        group {
          id
          Name
          Spectators
          Maintainers
          owner
          Devices {
            items {
              id
              deviceID
              groupID
              device {
                id
                MACAddress
                Name
                CurrentState {
                  CheckInSchedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  FirmwareVersion
                  Status
                  BatteryStatus
                  DigestSettings {
                    Type
                    Channel
                    Schedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    Receivers
                  }
                  Type
                  Probes {
                    SN
                    Type
                    Thermocouple
                    EepromId
                    SamplePeriod
                    Calibrations {
                      Timestamp
                      Expiry
                    }
                  }
                  Channels {
                    Name
                    Type
                    Resolution
                    AlarmSettings {
                      Type
                      CustomMessage
                      CustomExitMessage
                      Direction
                      Level
                      KnockInDelay
                      KnockOutDelay
                      Hysteresis
                    }
                    RenderSettings {
                      Unit
                      ScaleFactor
                      Offset
                    }
                  }
                  TrendConfig {
                    Period
                    Samples
                    AggregationMechanism
                  }
                  PreRollConfig {
                    Period
                    Samples
                    AggregationMechanism
                  }
                  SetupTime
                  LastCommsTime
                  LastConfigChangeTime
                }
                PendingState {
                  CheckInSchedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  FirmwareVersion
                  Status
                  BatteryStatus
                  DigestSettings {
                    Type
                    Channel
                    Schedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    Receivers
                  }
                  Type
                  Probes {
                    SN
                    Type
                    Thermocouple
                    EepromId
                    SamplePeriod
                    Calibrations {
                      Timestamp
                      Expiry
                    }
                  }
                  Channels {
                    Name
                    Type
                    Resolution
                    AlarmSettings {
                      Type
                      CustomMessage
                      CustomExitMessage
                      Direction
                      Level
                      KnockInDelay
                      KnockOutDelay
                      Hysteresis
                    }
                    RenderSettings {
                      Unit
                      ScaleFactor
                      Offset
                    }
                  }
                  TrendConfig {
                    Period
                    Samples
                    AggregationMechanism
                  }
                  PreRollConfig {
                    Period
                    Samples
                    AggregationMechanism
                  }
                  SetupTime
                  LastCommsTime
                  LastConfigChangeTime
                }
                LatestReadings {
                  ChannelIdx
                  Readings {
                    Value
                    Min
                    Max
                    Timestamp
                  }
                }
                Events {
                  Type
                  Source
                  Timestamp
                  Channel
                  AlarmSetting
                  Readings {
                    Value
                    Min
                    Max
                    Timestamp
                  }
                  LatestReadings {
                    Value
                    Min
                    Max
                    Timestamp
                  }
                  Processed
                }
                Digests {
                  DigestSetting {
                    Type
                    Channel
                    Schedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    Receivers
                  }
                  ChannelDigests {
                    StartTimestamp
                    EndTimestamp
                    MinValue
                    AvgValue
                    MaxValue
                    DurationInAlarm
                    AlarmsEntered
                    AlarmsExited
                  }
                  DeviceDigest {
                    StartTimestamp
                    EndTimestamp
                    Uptime
                    HeapFreeLWM
                    BatteryConsumed
                  }
                  TimeSubmitted
                }
                Spectators
                Maintainers
                Tags
                EmailSettings {
                  DigestEnabled
                  EventsEnabled
                  PushEnabled
                  CustomBranding
                }
                owner
                Management {
                  DisableUpdates
                }
                Groups {
                  items {
                    id
                    deviceID
                    groupID
                    device {
                      id
                      MACAddress
                      Name
                      CurrentState {
                        FirmwareVersion
                        Status
                        BatteryStatus
                        Type
                        SetupTime
                        LastCommsTime
                        LastConfigChangeTime
                      }
                      PendingState {
                        FirmwareVersion
                        Status
                        BatteryStatus
                        Type
                        SetupTime
                        LastCommsTime
                        LastConfigChangeTime
                      }
                      LatestReadings {
                        ChannelIdx
                      }
                      Events {
                        Type
                        Source
                        Timestamp
                        Channel
                        AlarmSetting
                        Processed
                      }
                      Digests {
                        TimeSubmitted
                      }
                      Spectators
                      Maintainers
                      Tags
                      EmailSettings {
                        DigestEnabled
                        EventsEnabled
                        PushEnabled
                        CustomBranding
                      }
                      owner
                      Management {
                        DisableUpdates
                      }
                      Groups {
                        nextToken
                        startedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    group {
                      id
                      Name
                      Spectators
                      Maintainers
                      owner
                      Devices {
                        nextToken
                        startedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    owner
                    Spectators
                    Maintainers
                  }
                  nextToken
                  startedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              group {
                id
                Name
                Spectators
                Maintainers
                owner
                Devices {
                  items {
                    id
                    deviceID
                    groupID
                    device {
                      id
                      MACAddress
                      Name
                      CurrentState {
                        FirmwareVersion
                        Status
                        BatteryStatus
                        Type
                        SetupTime
                        LastCommsTime
                        LastConfigChangeTime
                      }
                      PendingState {
                        FirmwareVersion
                        Status
                        BatteryStatus
                        Type
                        SetupTime
                        LastCommsTime
                        LastConfigChangeTime
                      }
                      LatestReadings {
                        ChannelIdx
                      }
                      Events {
                        Type
                        Source
                        Timestamp
                        Channel
                        AlarmSetting
                        Processed
                      }
                      Digests {
                        TimeSubmitted
                      }
                      Spectators
                      Maintainers
                      Tags
                      EmailSettings {
                        DigestEnabled
                        EventsEnabled
                        PushEnabled
                        CustomBranding
                      }
                      owner
                      Management {
                        DisableUpdates
                      }
                      Groups {
                        nextToken
                        startedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    group {
                      id
                      Name
                      Spectators
                      Maintainers
                      owner
                      Devices {
                        nextToken
                        startedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    owner
                    Spectators
                    Maintainers
                  }
                  nextToken
                  startedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              Spectators
              Maintainers
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        Spectators
        Maintainers
      }
      nextToken
      startedAt
    }
  }
`;
