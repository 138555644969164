import {
    Button,
    ButtonGroup,
    Col,
    Container,
    Form,
    FormGroup,
    FormInput, FormSelect,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import React from "react";
import {ESPLoader} from "esp-web-flasher";


function formatMacAddr(macAddr) {
    return macAddr
        .map((value) => value.toString(16).toUpperCase().padStart(2, "0"))
        .join(":");
}

export default class ConnectForProgrammingStage extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            macValid: false,
            mac: "",
            prefix: "98:8B:AD:",
            connecting: false
        };

        this.connectEspProg = this.connectEspProg.bind(this);
    }


    async connectEspProg(e) {
        this.setState(ps => {
            ps.connecting = true;
            return ps;
        });
        try {
            // - Request a port and open a connection.
            let options = {filters:[{usbVendorId: 12346, usbProductId: 2}]};
            const port = await navigator.serial.requestPort(options);
            let portInfo = await port.getInfo();
            console.log(JSON.stringify(portInfo))
            const logger = {
                log: (...args) => console.log(...args),
                debug: (...args) => console.log(...args),
                error: (...args) => console.log(...args),
            }

            logger.log("Connecting...");
            await port.open({ baudRate: 115200 });

            logger.log("Connected successfully.");

            const esploader = new ESPLoader(port, logger);
            await esploader.initialize();

            console.log("Connected to " + esploader.chipName);

            let stub = await esploader.runStub();
            console.log("connected");

            if(this.props.handleSuccess !== undefined)
            {
                this.props.handleSuccess(port, esploader, stub, formatMacAddr(esploader.macAddr()));
            }
        } catch (e) {
            console.log(e)
            this.setState(ps => {
                ps.connecting = false;
                return ps;
            });
        }
    }

    render() {
        return (
            <Container fluid className="main-content-container px-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-4">
                    <PageTitle sm="4" title={"Connect for programming"} subtitle="Programming"
                               className="text-sm-left"/>
                </Row>
                <Row>
                    Please place the unit into the jig, insert the USB connector and set the switch to programming the click connect below, you will be prompted to select the device from a list
                </Row>
                <Row noGutters className="page-header py-4">
                    <ButtonGroup vertical>
                        <Button disabled={this.state.connecting} size="lg" onClick={this.connectEspProg}>{this.state.connecting?"Connecting":"Connect"}</Button>
                    </ButtonGroup>
                </Row>
            </Container>)
    }
}