
import React from "react";
import {
  Container, FormInput,
  Row
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import {Auth} from 'aws-amplify';

const AWS = require('aws-sdk')
AWS.config.update({region:'eu-west-1'});

const USER_POOL_ID = 'eu-west-1_S465LzBpt'



class SearchUser extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
    this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
    this.searchForUsers = this.searchForUsers.bind(this);
  }

  handleSearchTermChange(event)
  {

    const { value } = event.target;
    if(value.length >= 3)
    {
      this.setState(ps=>
      {
        ps.searchTerm = value;
        ps.loading = true;
        return ps;
      }, ()=>{
        this.searchForUsers(value);
      })
    }
  }

  searchForUsers(email)
  {
    this.state.cisp?.listUsers({
      "AttributesToGet": [
        "email"
      ],
      "Filter": "email ^= \"" + email + "\"",
      "Limit": 10,
      "UserPoolId": USER_POOL_ID
    }).promise().then(res=>
    {
      console.log(res.Users)
      this.setState(ps=>
      {
        ps.users = res.Users;
        ps.loading = false;
        return ps;
      })
    }).catch(err=>
        console.log(err)
    )
  }

  componentDidMount() {
    Auth.currentCredentials().then(cred=>
    {
      console.log("using credentials: "+ JSON.stringify(cred) );
      AWS.config.credentials = cred;

      AWS.config.update({
        accessKeyId: cred.accessKeyId,
        secretAccessKey: cred.secretAccessKey,
        sessionToken: cred.sessionToken,
        region: 'eu-west-1'
      });
      let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({apiVersion: '2016-04-18'});
      this.setState(ps=>
      {
        ps.cisp = cognitoidentityserviceprovider;
        return ps;
      })
    })
  }

  render() {

    const {
      users,
      searchTerm,
        loading
    } = this.state;
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title={"User Search"} subtitle="Users"
                     className="text-sm-left"/>
          <FormInput placeholder="Enter email address here" className="mb-2" onChange={this.handleSearchTermChange} />
        </Row>
        {/* First Row of Devices */}
        <Row>
          <table>
            <th>
              <h3>{loading?"Loading...":users?"Results for '" + searchTerm + "' (" + users?.length + ")":"Please enter a search term"}</h3>

            </th>
            {users?.map((user, idx)=>{
              return(
                  <tr key={idx}>
                    <a href={"/all-devices/user/"+user.Username}>
                      {user.Attributes[0].Value}
                    </a>
                  </tr>
              )
            })}
          </table>
        </Row>
      </Container>
    );
  }
}
export default SearchUser;
