/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateCurrentState = /* GraphQL */ `
  mutation UpdateCurrentState(
    $id: ID!
    $newState: DeviceStateInput!
    $expectedVersion: Int!
  ) {
    updateCurrentState(
      id: $id
      newState: $newState
      expectedVersion: $expectedVersion
    ) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const addEvent = /* GraphQL */ `
  mutation AddEvent(
    $id: ID!
    $event: EventInput!
    $overwriteIdx: Int
    $expectedVersion: Int!
  ) {
    addEvent(
      id: $id
      event: $event
      overwriteIdx: $overwriteIdx
      expectedVersion: $expectedVersion
    ) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const addReadings = /* GraphQL */ `
  mutation AddReadings(
    $id: ID!
    $readings: ChannelReadingInput!
    $overwriteIdx: Int
    $expectedVersion: Int!
    $timeSubmitted: AWSTimestamp!
  ) {
    addReadings(
      id: $id
      readings: $readings
      overwriteIdx: $overwriteIdx
      expectedVersion: $expectedVersion
      timeSubmitted: $timeSubmitted
    ) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const addDigest = /* GraphQL */ `
  mutation AddDigest(
    $id: ID!
    $digest: DigestInput!
    $overwriteIdx: Int
    $expectedVersion: Int!
  ) {
    addDigest(
      id: $id
      digest: $digest
      overwriteIdx: $overwriteIdx
      expectedVersion: $expectedVersion
    ) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const markEventProcessed = /* GraphQL */ `
  mutation MarkEventProcessed(
    $id: ID!
    $eventIdx: Int!
    $expectedVersion: Int!
  ) {
    markEventProcessed(
      id: $id
      eventIdx: $eventIdx
      expectedVersion: $expectedVersion
    ) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const addSpectator = /* GraphQL */ `
  mutation AddSpectator($id: ID!, $username: String!, $expectedVersion: Int!) {
    addSpectator(
      id: $id
      username: $username
      expectedVersion: $expectedVersion
    ) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const removeSpectator = /* GraphQL */ `
  mutation RemoveSpectator($id: ID!, $index: Int!, $expectedVersion: Int!) {
    removeSpectator(id: $id, index: $index, expectedVersion: $expectedVersion) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const addMaintainer = /* GraphQL */ `
  mutation AddMaintainer($id: ID!, $username: String!, $expectedVersion: Int!) {
    addMaintainer(
      id: $id
      username: $username
      expectedVersion: $expectedVersion
    ) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const removeMaintainer = /* GraphQL */ `
  mutation RemoveMaintainer($id: ID!, $index: Int!, $expectedVersion: Int!) {
    removeMaintainer(
      id: $id
      index: $index
      expectedVersion: $expectedVersion
    ) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const addChannelAlarmSetting = /* GraphQL */ `
  mutation AddChannelAlarmSetting(
    $id: ID!
    $channelIndex: Int!
    $alarmSetting: AlarmSettingInput!
    $expectedVersion: Int!
  ) {
    addChannelAlarmSetting(
      id: $id
      channelIndex: $channelIndex
      alarmSetting: $alarmSetting
      expectedVersion: $expectedVersion
    ) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const removeChannelAlarmSetting = /* GraphQL */ `
  mutation RemoveChannelAlarmSetting(
    $id: ID!
    $channelIndex: Int!
    $index: Int!
    $expectedVersion: Int!
  ) {
    removeChannelAlarmSetting(
      id: $id
      channelIndex: $channelIndex
      index: $index
      expectedVersion: $expectedVersion
    ) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const addDigestSetting = /* GraphQL */ `
  mutation AddDigestSetting(
    $id: ID!
    $digestSetting: DigestSettingInput!
    $expectedVersion: Int!
  ) {
    addDigestSetting(
      id: $id
      digestSetting: $digestSetting
      expectedVersion: $expectedVersion
    ) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDigestSetting = /* GraphQL */ `
  mutation UpdateDigestSetting(
    $id: ID!
    $index: Int!
    $digestSetting: DigestSettingInput!
    $expectedVersion: Int!
  ) {
    updateDigestSetting(
      id: $id
      index: $index
      digestSetting: $digestSetting
      expectedVersion: $expectedVersion
    ) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const removeDigestSetting = /* GraphQL */ `
  mutation RemoveDigestSetting($id: ID!, $index: Int!, $expectedVersion: Int!) {
    removeDigestSetting(
      id: $id
      index: $index
      expectedVersion: $expectedVersion
    ) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateProbeSamplePeriod = /* GraphQL */ `
  mutation UpdateProbeSamplePeriod(
    $id: ID!
    $probeIndex: Int!
    $samplePeriod: Int!
    $expectedVersion: Int!
  ) {
    updateProbeSamplePeriod(
      id: $id
      probeIndex: $probeIndex
      samplePeriod: $samplePeriod
      expectedVersion: $expectedVersion
    ) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDeviceName = /* GraphQL */ `
  mutation UpdateDeviceName(
    $id: ID!
    $newName: String!
    $expectedVersion: Int!
  ) {
    updateDeviceName(
      id: $id
      newName: $newName
      expectedVersion: $expectedVersion
    ) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateChannelName = /* GraphQL */ `
  mutation UpdateChannelName(
    $id: ID!
    $channelIndex: Int!
    $newName: String!
    $expectedVersion: Int!
  ) {
    updateChannelName(
      id: $id
      channelIndex: $channelIndex
      newName: $newName
      expectedVersion: $expectedVersion
    ) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDeviceFirmware = /* GraphQL */ `
  mutation UpdateDeviceFirmware(
    $id: ID!
    $firmwareVersion: String!
    $expectedVersion: Int!
  ) {
    updateDeviceFirmware(
      id: $id
      firmwareVersion: $firmwareVersion
      expectedVersion: $expectedVersion
    ) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const addTag = /* GraphQL */ `
  mutation AddTag($id: ID!, $tag: String!, $expectedVersion: Int!) {
    addTag(id: $id, tag: $tag, expectedVersion: $expectedVersion) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const removeTag = /* GraphQL */ `
  mutation RemoveTag($id: ID!, $index: Int!, $expectedVersion: Int!) {
    removeTag(id: $id, index: $index, expectedVersion: $expectedVersion) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRenderSettings = /* GraphQL */ `
  mutation UpdateRenderSettings(
    $id: ID!
    $channelIdx: Int!
    $newRenderSettings: RenderSettingsInput!
    $expectedVersion: Int!
  ) {
    updateRenderSettings(
      id: $id
      channelIdx: $channelIdx
      newRenderSettings: $newRenderSettings
      expectedVersion: $expectedVersion
    ) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const enableDigestEmails = /* GraphQL */ `
  mutation EnableDigestEmails($id: ID!, $expectedVersion: Int!) {
    enableDigestEmails(id: $id, expectedVersion: $expectedVersion) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const disableDigestEmails = /* GraphQL */ `
  mutation DisableDigestEmails($id: ID!, $expectedVersion: Int!) {
    disableDigestEmails(id: $id, expectedVersion: $expectedVersion) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCheckInPeriod = /* GraphQL */ `
  mutation UpdateCheckInPeriod(
    $id: ID!
    $checkInPeriod: Int!
    $expectedVersion: Int!
  ) {
    updateCheckInPeriod(
      id: $id
      checkInPeriod: $checkInPeriod
      expectedVersion: $expectedVersion
    ) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const enableEventEmails = /* GraphQL */ `
  mutation EnableEventEmails($id: ID!, $expectedVersion: Int!) {
    enableEventEmails(id: $id, expectedVersion: $expectedVersion) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const disableEventEmails = /* GraphQL */ `
  mutation DisableEventEmails($id: ID!, $expectedVersion: Int!) {
    disableEventEmails(id: $id, expectedVersion: $expectedVersion) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const enablePushNotifications = /* GraphQL */ `
  mutation EnablePushNotifications($id: ID!, $expectedVersion: Int!) {
    enablePushNotifications(id: $id, expectedVersion: $expectedVersion) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const disablePushNotifications = /* GraphQL */ `
  mutation DisablePushNotifications($id: ID!, $expectedVersion: Int!) {
    disablePushNotifications(id: $id, expectedVersion: $expectedVersion) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice(
    $input: CreateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    createDevice(input: $input, condition: $condition) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice(
    $input: UpdateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    updateDevice(input: $input, condition: $condition) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice(
    $input: DeleteDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    deleteDevice(input: $input, condition: $condition) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          Thermocouple
          EepromId
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        EventsEnabled
        PushEnabled
        CustomBranding
      }
      owner
      Management {
        DisableUpdates
      }
      Groups {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      Name
      Spectators
      Maintainers
      owner
      Devices {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      Name
      Spectators
      Maintainers
      owner
      Devices {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      Name
      Spectators
      Maintainers
      owner
      Devices {
        items {
          id
          deviceID
          groupID
          device {
            id
            MACAddress
            Name
            CurrentState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            PendingState {
              CheckInSchedule {
                Type
                Datum
                Period
                CronString
              }
              FirmwareVersion
              Status
              BatteryStatus
              DigestSettings {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              Type
              Probes {
                SN
                Type
                Thermocouple
                EepromId
                SamplePeriod
                Calibrations {
                  Timestamp
                  Expiry
                }
              }
              Channels {
                Name
                Type
                Resolution
                AlarmSettings {
                  Type
                  CustomMessage
                  CustomExitMessage
                  Direction
                  Level
                  KnockInDelay
                  KnockOutDelay
                  Hysteresis
                }
                RenderSettings {
                  Unit
                  ScaleFactor
                  Offset
                }
              }
              TrendConfig {
                Period
                Samples
                AggregationMechanism
              }
              PreRollConfig {
                Period
                Samples
                AggregationMechanism
              }
              SetupTime
              LastCommsTime
              LastConfigChangeTime
            }
            LatestReadings {
              ChannelIdx
              Readings {
                Value
                Min
                Max
                Timestamp
              }
            }
            Events {
              Type
              Source
              Timestamp
              Channel
              AlarmSetting
              Readings {
                Value
                Min
                Max
                Timestamp
              }
              LatestReadings {
                Value
                Min
                Max
                Timestamp
              }
              Processed
            }
            Digests {
              DigestSetting {
                Type
                Channel
                Schedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                Receivers
              }
              ChannelDigests {
                StartTimestamp
                EndTimestamp
                MinValue
                AvgValue
                MaxValue
                DurationInAlarm
                AlarmsEntered
                AlarmsExited
              }
              DeviceDigest {
                StartTimestamp
                EndTimestamp
                Uptime
                HeapFreeLWM
                BatteryConsumed
              }
              TimeSubmitted
            }
            Spectators
            Maintainers
            Tags
            EmailSettings {
              DigestEnabled
              EventsEnabled
              PushEnabled
              CustomBranding
            }
            owner
            Management {
              DisableUpdates
            }
            Groups {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          group {
            id
            Name
            Spectators
            Maintainers
            owner
            Devices {
              items {
                id
                deviceID
                groupID
                device {
                  id
                  MACAddress
                  Name
                  CurrentState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  PendingState {
                    CheckInSchedule {
                      Type
                      Datum
                      Period
                      CronString
                    }
                    FirmwareVersion
                    Status
                    BatteryStatus
                    DigestSettings {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    Type
                    Probes {
                      SN
                      Type
                      Thermocouple
                      EepromId
                      SamplePeriod
                      Calibrations {
                        Timestamp
                        Expiry
                      }
                    }
                    Channels {
                      Name
                      Type
                      Resolution
                      AlarmSettings {
                        Type
                        CustomMessage
                        CustomExitMessage
                        Direction
                        Level
                        KnockInDelay
                        KnockOutDelay
                        Hysteresis
                      }
                      RenderSettings {
                        Unit
                        ScaleFactor
                        Offset
                      }
                    }
                    TrendConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    PreRollConfig {
                      Period
                      Samples
                      AggregationMechanism
                    }
                    SetupTime
                    LastCommsTime
                    LastConfigChangeTime
                  }
                  LatestReadings {
                    ChannelIdx
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                  }
                  Events {
                    Type
                    Source
                    Timestamp
                    Channel
                    AlarmSetting
                    Readings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    LatestReadings {
                      Value
                      Min
                      Max
                      Timestamp
                    }
                    Processed
                  }
                  Digests {
                    DigestSetting {
                      Type
                      Channel
                      Schedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      Receivers
                    }
                    ChannelDigests {
                      StartTimestamp
                      EndTimestamp
                      MinValue
                      AvgValue
                      MaxValue
                      DurationInAlarm
                      AlarmsEntered
                      AlarmsExited
                    }
                    DeviceDigest {
                      StartTimestamp
                      EndTimestamp
                      Uptime
                      HeapFreeLWM
                      BatteryConsumed
                    }
                    TimeSubmitted
                  }
                  Spectators
                  Maintainers
                  Tags
                  EmailSettings {
                    DigestEnabled
                    EventsEnabled
                    PushEnabled
                    CustomBranding
                  }
                  owner
                  Management {
                    DisableUpdates
                  }
                  Groups {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                group {
                  id
                  Name
                  Spectators
                  Maintainers
                  owner
                  Devices {
                    items {
                      id
                      deviceID
                      groupID
                      device {
                        id
                        MACAddress
                        Name
                        Spectators
                        Maintainers
                        Tags
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      group {
                        id
                        Name
                        Spectators
                        Maintainers
                        owner
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                      }
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      owner
                      Spectators
                      Maintainers
                    }
                    nextToken
                    startedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                Spectators
                Maintainers
              }
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          Spectators
          Maintainers
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDeviceGroups = /* GraphQL */ `
  mutation CreateDeviceGroups(
    $input: CreateDeviceGroupsInput!
    $condition: ModelDeviceGroupsConditionInput
  ) {
    createDeviceGroups(input: $input, condition: $condition) {
      id
      deviceID
      groupID
      device {
        id
        MACAddress
        Name
        CurrentState {
          CheckInSchedule {
            Type
            Datum
            Period
            CronString
          }
          FirmwareVersion
          Status
          BatteryStatus
          DigestSettings {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          Type
          Probes {
            SN
            Type
            Thermocouple
            EepromId
            SamplePeriod
            Calibrations {
              Timestamp
              Expiry
            }
          }
          Channels {
            Name
            Type
            Resolution
            AlarmSettings {
              Type
              CustomMessage
              CustomExitMessage
              Direction
              Level
              KnockInDelay
              KnockOutDelay
              Hysteresis
            }
            RenderSettings {
              Unit
              ScaleFactor
              Offset
            }
          }
          TrendConfig {
            Period
            Samples
            AggregationMechanism
          }
          PreRollConfig {
            Period
            Samples
            AggregationMechanism
          }
          SetupTime
          LastCommsTime
          LastConfigChangeTime
        }
        PendingState {
          CheckInSchedule {
            Type
            Datum
            Period
            CronString
          }
          FirmwareVersion
          Status
          BatteryStatus
          DigestSettings {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          Type
          Probes {
            SN
            Type
            Thermocouple
            EepromId
            SamplePeriod
            Calibrations {
              Timestamp
              Expiry
            }
          }
          Channels {
            Name
            Type
            Resolution
            AlarmSettings {
              Type
              CustomMessage
              CustomExitMessage
              Direction
              Level
              KnockInDelay
              KnockOutDelay
              Hysteresis
            }
            RenderSettings {
              Unit
              ScaleFactor
              Offset
            }
          }
          TrendConfig {
            Period
            Samples
            AggregationMechanism
          }
          PreRollConfig {
            Period
            Samples
            AggregationMechanism
          }
          SetupTime
          LastCommsTime
          LastConfigChangeTime
        }
        LatestReadings {
          ChannelIdx
          Readings {
            Value
            Min
            Max
            Timestamp
          }
        }
        Events {
          Type
          Source
          Timestamp
          Channel
          AlarmSetting
          Readings {
            Value
            Min
            Max
            Timestamp
          }
          LatestReadings {
            Value
            Min
            Max
            Timestamp
          }
          Processed
        }
        Digests {
          DigestSetting {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          ChannelDigests {
            StartTimestamp
            EndTimestamp
            MinValue
            AvgValue
            MaxValue
            DurationInAlarm
            AlarmsEntered
            AlarmsExited
          }
          DeviceDigest {
            StartTimestamp
            EndTimestamp
            Uptime
            HeapFreeLWM
            BatteryConsumed
          }
          TimeSubmitted
        }
        Spectators
        Maintainers
        Tags
        EmailSettings {
          DigestEnabled
          EventsEnabled
          PushEnabled
          CustomBranding
        }
        owner
        Management {
          DisableUpdates
        }
        Groups {
          items {
            id
            deviceID
            groupID
            device {
              id
              MACAddress
              Name
              CurrentState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              PendingState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              LatestReadings {
                ChannelIdx
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
              }
              Events {
                Type
                Source
                Timestamp
                Channel
                AlarmSetting
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                LatestReadings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                Processed
              }
              Digests {
                DigestSetting {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                ChannelDigests {
                  StartTimestamp
                  EndTimestamp
                  MinValue
                  AvgValue
                  MaxValue
                  DurationInAlarm
                  AlarmsEntered
                  AlarmsExited
                }
                DeviceDigest {
                  StartTimestamp
                  EndTimestamp
                  Uptime
                  HeapFreeLWM
                  BatteryConsumed
                }
                TimeSubmitted
              }
              Spectators
              Maintainers
              Tags
              EmailSettings {
                DigestEnabled
                EventsEnabled
                PushEnabled
                CustomBranding
              }
              owner
              Management {
                DisableUpdates
              }
              Groups {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            group {
              id
              Name
              Spectators
              Maintainers
              owner
              Devices {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            Spectators
            Maintainers
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      group {
        id
        Name
        Spectators
        Maintainers
        owner
        Devices {
          items {
            id
            deviceID
            groupID
            device {
              id
              MACAddress
              Name
              CurrentState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              PendingState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              LatestReadings {
                ChannelIdx
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
              }
              Events {
                Type
                Source
                Timestamp
                Channel
                AlarmSetting
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                LatestReadings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                Processed
              }
              Digests {
                DigestSetting {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                ChannelDigests {
                  StartTimestamp
                  EndTimestamp
                  MinValue
                  AvgValue
                  MaxValue
                  DurationInAlarm
                  AlarmsEntered
                  AlarmsExited
                }
                DeviceDigest {
                  StartTimestamp
                  EndTimestamp
                  Uptime
                  HeapFreeLWM
                  BatteryConsumed
                }
                TimeSubmitted
              }
              Spectators
              Maintainers
              Tags
              EmailSettings {
                DigestEnabled
                EventsEnabled
                PushEnabled
                CustomBranding
              }
              owner
              Management {
                DisableUpdates
              }
              Groups {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            group {
              id
              Name
              Spectators
              Maintainers
              owner
              Devices {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            Spectators
            Maintainers
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      Spectators
      Maintainers
    }
  }
`;
export const updateDeviceGroups = /* GraphQL */ `
  mutation UpdateDeviceGroups(
    $input: UpdateDeviceGroupsInput!
    $condition: ModelDeviceGroupsConditionInput
  ) {
    updateDeviceGroups(input: $input, condition: $condition) {
      id
      deviceID
      groupID
      device {
        id
        MACAddress
        Name
        CurrentState {
          CheckInSchedule {
            Type
            Datum
            Period
            CronString
          }
          FirmwareVersion
          Status
          BatteryStatus
          DigestSettings {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          Type
          Probes {
            SN
            Type
            Thermocouple
            EepromId
            SamplePeriod
            Calibrations {
              Timestamp
              Expiry
            }
          }
          Channels {
            Name
            Type
            Resolution
            AlarmSettings {
              Type
              CustomMessage
              CustomExitMessage
              Direction
              Level
              KnockInDelay
              KnockOutDelay
              Hysteresis
            }
            RenderSettings {
              Unit
              ScaleFactor
              Offset
            }
          }
          TrendConfig {
            Period
            Samples
            AggregationMechanism
          }
          PreRollConfig {
            Period
            Samples
            AggregationMechanism
          }
          SetupTime
          LastCommsTime
          LastConfigChangeTime
        }
        PendingState {
          CheckInSchedule {
            Type
            Datum
            Period
            CronString
          }
          FirmwareVersion
          Status
          BatteryStatus
          DigestSettings {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          Type
          Probes {
            SN
            Type
            Thermocouple
            EepromId
            SamplePeriod
            Calibrations {
              Timestamp
              Expiry
            }
          }
          Channels {
            Name
            Type
            Resolution
            AlarmSettings {
              Type
              CustomMessage
              CustomExitMessage
              Direction
              Level
              KnockInDelay
              KnockOutDelay
              Hysteresis
            }
            RenderSettings {
              Unit
              ScaleFactor
              Offset
            }
          }
          TrendConfig {
            Period
            Samples
            AggregationMechanism
          }
          PreRollConfig {
            Period
            Samples
            AggregationMechanism
          }
          SetupTime
          LastCommsTime
          LastConfigChangeTime
        }
        LatestReadings {
          ChannelIdx
          Readings {
            Value
            Min
            Max
            Timestamp
          }
        }
        Events {
          Type
          Source
          Timestamp
          Channel
          AlarmSetting
          Readings {
            Value
            Min
            Max
            Timestamp
          }
          LatestReadings {
            Value
            Min
            Max
            Timestamp
          }
          Processed
        }
        Digests {
          DigestSetting {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          ChannelDigests {
            StartTimestamp
            EndTimestamp
            MinValue
            AvgValue
            MaxValue
            DurationInAlarm
            AlarmsEntered
            AlarmsExited
          }
          DeviceDigest {
            StartTimestamp
            EndTimestamp
            Uptime
            HeapFreeLWM
            BatteryConsumed
          }
          TimeSubmitted
        }
        Spectators
        Maintainers
        Tags
        EmailSettings {
          DigestEnabled
          EventsEnabled
          PushEnabled
          CustomBranding
        }
        owner
        Management {
          DisableUpdates
        }
        Groups {
          items {
            id
            deviceID
            groupID
            device {
              id
              MACAddress
              Name
              CurrentState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              PendingState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              LatestReadings {
                ChannelIdx
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
              }
              Events {
                Type
                Source
                Timestamp
                Channel
                AlarmSetting
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                LatestReadings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                Processed
              }
              Digests {
                DigestSetting {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                ChannelDigests {
                  StartTimestamp
                  EndTimestamp
                  MinValue
                  AvgValue
                  MaxValue
                  DurationInAlarm
                  AlarmsEntered
                  AlarmsExited
                }
                DeviceDigest {
                  StartTimestamp
                  EndTimestamp
                  Uptime
                  HeapFreeLWM
                  BatteryConsumed
                }
                TimeSubmitted
              }
              Spectators
              Maintainers
              Tags
              EmailSettings {
                DigestEnabled
                EventsEnabled
                PushEnabled
                CustomBranding
              }
              owner
              Management {
                DisableUpdates
              }
              Groups {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            group {
              id
              Name
              Spectators
              Maintainers
              owner
              Devices {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            Spectators
            Maintainers
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      group {
        id
        Name
        Spectators
        Maintainers
        owner
        Devices {
          items {
            id
            deviceID
            groupID
            device {
              id
              MACAddress
              Name
              CurrentState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              PendingState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              LatestReadings {
                ChannelIdx
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
              }
              Events {
                Type
                Source
                Timestamp
                Channel
                AlarmSetting
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                LatestReadings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                Processed
              }
              Digests {
                DigestSetting {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                ChannelDigests {
                  StartTimestamp
                  EndTimestamp
                  MinValue
                  AvgValue
                  MaxValue
                  DurationInAlarm
                  AlarmsEntered
                  AlarmsExited
                }
                DeviceDigest {
                  StartTimestamp
                  EndTimestamp
                  Uptime
                  HeapFreeLWM
                  BatteryConsumed
                }
                TimeSubmitted
              }
              Spectators
              Maintainers
              Tags
              EmailSettings {
                DigestEnabled
                EventsEnabled
                PushEnabled
                CustomBranding
              }
              owner
              Management {
                DisableUpdates
              }
              Groups {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            group {
              id
              Name
              Spectators
              Maintainers
              owner
              Devices {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            Spectators
            Maintainers
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      Spectators
      Maintainers
    }
  }
`;
export const deleteDeviceGroups = /* GraphQL */ `
  mutation DeleteDeviceGroups(
    $input: DeleteDeviceGroupsInput!
    $condition: ModelDeviceGroupsConditionInput
  ) {
    deleteDeviceGroups(input: $input, condition: $condition) {
      id
      deviceID
      groupID
      device {
        id
        MACAddress
        Name
        CurrentState {
          CheckInSchedule {
            Type
            Datum
            Period
            CronString
          }
          FirmwareVersion
          Status
          BatteryStatus
          DigestSettings {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          Type
          Probes {
            SN
            Type
            Thermocouple
            EepromId
            SamplePeriod
            Calibrations {
              Timestamp
              Expiry
            }
          }
          Channels {
            Name
            Type
            Resolution
            AlarmSettings {
              Type
              CustomMessage
              CustomExitMessage
              Direction
              Level
              KnockInDelay
              KnockOutDelay
              Hysteresis
            }
            RenderSettings {
              Unit
              ScaleFactor
              Offset
            }
          }
          TrendConfig {
            Period
            Samples
            AggregationMechanism
          }
          PreRollConfig {
            Period
            Samples
            AggregationMechanism
          }
          SetupTime
          LastCommsTime
          LastConfigChangeTime
        }
        PendingState {
          CheckInSchedule {
            Type
            Datum
            Period
            CronString
          }
          FirmwareVersion
          Status
          BatteryStatus
          DigestSettings {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          Type
          Probes {
            SN
            Type
            Thermocouple
            EepromId
            SamplePeriod
            Calibrations {
              Timestamp
              Expiry
            }
          }
          Channels {
            Name
            Type
            Resolution
            AlarmSettings {
              Type
              CustomMessage
              CustomExitMessage
              Direction
              Level
              KnockInDelay
              KnockOutDelay
              Hysteresis
            }
            RenderSettings {
              Unit
              ScaleFactor
              Offset
            }
          }
          TrendConfig {
            Period
            Samples
            AggregationMechanism
          }
          PreRollConfig {
            Period
            Samples
            AggregationMechanism
          }
          SetupTime
          LastCommsTime
          LastConfigChangeTime
        }
        LatestReadings {
          ChannelIdx
          Readings {
            Value
            Min
            Max
            Timestamp
          }
        }
        Events {
          Type
          Source
          Timestamp
          Channel
          AlarmSetting
          Readings {
            Value
            Min
            Max
            Timestamp
          }
          LatestReadings {
            Value
            Min
            Max
            Timestamp
          }
          Processed
        }
        Digests {
          DigestSetting {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          ChannelDigests {
            StartTimestamp
            EndTimestamp
            MinValue
            AvgValue
            MaxValue
            DurationInAlarm
            AlarmsEntered
            AlarmsExited
          }
          DeviceDigest {
            StartTimestamp
            EndTimestamp
            Uptime
            HeapFreeLWM
            BatteryConsumed
          }
          TimeSubmitted
        }
        Spectators
        Maintainers
        Tags
        EmailSettings {
          DigestEnabled
          EventsEnabled
          PushEnabled
          CustomBranding
        }
        owner
        Management {
          DisableUpdates
        }
        Groups {
          items {
            id
            deviceID
            groupID
            device {
              id
              MACAddress
              Name
              CurrentState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              PendingState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              LatestReadings {
                ChannelIdx
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
              }
              Events {
                Type
                Source
                Timestamp
                Channel
                AlarmSetting
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                LatestReadings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                Processed
              }
              Digests {
                DigestSetting {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                ChannelDigests {
                  StartTimestamp
                  EndTimestamp
                  MinValue
                  AvgValue
                  MaxValue
                  DurationInAlarm
                  AlarmsEntered
                  AlarmsExited
                }
                DeviceDigest {
                  StartTimestamp
                  EndTimestamp
                  Uptime
                  HeapFreeLWM
                  BatteryConsumed
                }
                TimeSubmitted
              }
              Spectators
              Maintainers
              Tags
              EmailSettings {
                DigestEnabled
                EventsEnabled
                PushEnabled
                CustomBranding
              }
              owner
              Management {
                DisableUpdates
              }
              Groups {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            group {
              id
              Name
              Spectators
              Maintainers
              owner
              Devices {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            Spectators
            Maintainers
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      group {
        id
        Name
        Spectators
        Maintainers
        owner
        Devices {
          items {
            id
            deviceID
            groupID
            device {
              id
              MACAddress
              Name
              CurrentState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              PendingState {
                CheckInSchedule {
                  Type
                  Datum
                  Period
                  CronString
                }
                FirmwareVersion
                Status
                BatteryStatus
                DigestSettings {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                Type
                Probes {
                  SN
                  Type
                  Thermocouple
                  EepromId
                  SamplePeriod
                  Calibrations {
                    Timestamp
                    Expiry
                  }
                }
                Channels {
                  Name
                  Type
                  Resolution
                  AlarmSettings {
                    Type
                    CustomMessage
                    CustomExitMessage
                    Direction
                    Level
                    KnockInDelay
                    KnockOutDelay
                    Hysteresis
                  }
                  RenderSettings {
                    Unit
                    ScaleFactor
                    Offset
                  }
                }
                TrendConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                PreRollConfig {
                  Period
                  Samples
                  AggregationMechanism
                }
                SetupTime
                LastCommsTime
                LastConfigChangeTime
              }
              LatestReadings {
                ChannelIdx
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
              }
              Events {
                Type
                Source
                Timestamp
                Channel
                AlarmSetting
                Readings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                LatestReadings {
                  Value
                  Min
                  Max
                  Timestamp
                }
                Processed
              }
              Digests {
                DigestSetting {
                  Type
                  Channel
                  Schedule {
                    Type
                    Datum
                    Period
                    CronString
                  }
                  Receivers
                }
                ChannelDigests {
                  StartTimestamp
                  EndTimestamp
                  MinValue
                  AvgValue
                  MaxValue
                  DurationInAlarm
                  AlarmsEntered
                  AlarmsExited
                }
                DeviceDigest {
                  StartTimestamp
                  EndTimestamp
                  Uptime
                  HeapFreeLWM
                  BatteryConsumed
                }
                TimeSubmitted
              }
              Spectators
              Maintainers
              Tags
              EmailSettings {
                DigestEnabled
                EventsEnabled
                PushEnabled
                CustomBranding
              }
              owner
              Management {
                DisableUpdates
              }
              Groups {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            group {
              id
              Name
              Spectators
              Maintainers
              owner
              Devices {
                items {
                  id
                  deviceID
                  groupID
                  device {
                    id
                    MACAddress
                    Name
                    CurrentState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    PendingState {
                      CheckInSchedule {
                        Type
                        Datum
                        Period
                        CronString
                      }
                      FirmwareVersion
                      Status
                      BatteryStatus
                      DigestSettings {
                        Type
                        Channel
                        Receivers
                      }
                      Type
                      Probes {
                        SN
                        Type
                        Thermocouple
                        EepromId
                        SamplePeriod
                      }
                      Channels {
                        Name
                        Type
                        Resolution
                      }
                      TrendConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      PreRollConfig {
                        Period
                        Samples
                        AggregationMechanism
                      }
                      SetupTime
                      LastCommsTime
                      LastConfigChangeTime
                    }
                    LatestReadings {
                      ChannelIdx
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                    }
                    Events {
                      Type
                      Source
                      Timestamp
                      Channel
                      AlarmSetting
                      Readings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      LatestReadings {
                        Value
                        Min
                        Max
                        Timestamp
                      }
                      Processed
                    }
                    Digests {
                      DigestSetting {
                        Type
                        Channel
                        Receivers
                      }
                      ChannelDigests {
                        StartTimestamp
                        EndTimestamp
                        MinValue
                        AvgValue
                        MaxValue
                        DurationInAlarm
                        AlarmsEntered
                        AlarmsExited
                      }
                      DeviceDigest {
                        StartTimestamp
                        EndTimestamp
                        Uptime
                        HeapFreeLWM
                        BatteryConsumed
                      }
                      TimeSubmitted
                    }
                    Spectators
                    Maintainers
                    Tags
                    EmailSettings {
                      DigestEnabled
                      EventsEnabled
                      PushEnabled
                      CustomBranding
                    }
                    owner
                    Management {
                      DisableUpdates
                    }
                    Groups {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  group {
                    id
                    Name
                    Spectators
                    Maintainers
                    owner
                    Devices {
                      items {
                        id
                        deviceID
                        groupID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        Spectators
                        Maintainers
                      }
                      nextToken
                      startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                  }
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  owner
                  Spectators
                  Maintainers
                }
                nextToken
                startedAt
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            Spectators
            Maintainers
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      Spectators
      Maintainers
    }
  }
`;
