import { EventEmitter } from "events";

import Dispatcher from "./dispatcher";
import Constants from "./constants";
import getSidebarNavItems from "../data/sidebar-nav-items";

let _store = {
  menuVisible: false,
  navItems: getSidebarNavItems(),
  user: undefined
};

class Store extends EventEmitter {
  constructor() {
    super();

    this.registerToActions = this.registerToActions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);

    Dispatcher.register(this.registerToActions.bind(this));
  }

  registerToActions({ actionType, payload }) {
    switch (actionType) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      case Constants.ADD_SIDEBAR_ITEM:
        _store.navItems.push(payload);
        this.emit(Constants.CHANGE);
        break;
      case Constants.SET_SIDEBAR_ITEMS:
        _store.navItems = payload;
        this.emit(Constants.CHANGE);
        break;
      case Constants.SET_USER:
        _store.user = payload;
        this.emit(Constants.CHANGE);
        break;
      default:
    }
  }

  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.CHANGE);
  }

  getMenuState() {
    return _store.menuVisible;
  }

  getSidebarItems() {
    let groups = [];
    if(_store.user)
    {
      groups = _store.user?.signInUserSession?.accessToken?.payload['cognito:groups']??["user"];
    }
    console.log("Groups: " + groups)
    return _store.navItems.filter(item=>
    {
      if(item.requiresGroup !== undefined)
      {
        return groups.some(g=>item.requiresGroup.includes(g));
      }
      else
      {
        return true;
      }
    });
  }

  addChangeListener(callback) {
    this.on(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new Store();
