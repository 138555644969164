import React from "react";
import {
  Button, Form, FormGroup, FormInput, FormSelect,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading, Modal,
  ModalBody,
  ModalHeader, Slider
} from "shards-react";
import {addChannelAlarmSetting, removeChannelAlarmSetting} from "../../../graphql/mutations";
import {API} from "aws-amplify";

class AlarmSettingActionMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alarmSettings:[],
      addAlarmSettingModalOpen: 0,
      form:{
        alarmType: "RAISING",
        level: 0,
        enterMessage: "",
        exitMessage: ""
      }
    }
    this.handleFormItemChange = this.handleFormItemChange.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.addNewAlarmSetting = this.addNewAlarmSetting.bind(this);
  }


  static getDerivedStateFromProps(props, state) {
    // Any time the current user changes,
    // Reset any parts of state that are tied to that user.
    // In this simple example, that's just the email.
    const {device} = props;
    let alarmSettings = [];
    if(device)
    {
      device.CurrentState.Channels.forEach((channel, channelIdx) =>
      {
        alarmSettings[channelIdx] = [];
        channel.AlarmSettings.forEach((as, asIdx) =>
        {
          if(JSON.stringify(device.PendingState.Channels[channelIdx].AlarmSettings[asIdx]) !== JSON.stringify(device.CurrentState.Channels[channelIdx].AlarmSettings[asIdx]))
          {
            alarmSettings[channelIdx][asIdx] = as;
            alarmSettings[channelIdx][asIdx].PendingChange = true;
            if(device.PendingState.Channels[channelIdx].AlarmSettings[asIdx] === undefined)
            {
              alarmSettings[channelIdx][asIdx].PendingDeletion = true;
            }
          }
          else
          {
            alarmSettings[channelIdx][asIdx] = as;
            alarmSettings[channelIdx][asIdx].PendingChange = false;
          }
          alarmSettings[channelIdx][asIdx].Active = true;
        })
      })
      device.PendingState.Channels.forEach((channel, channelIdx) =>
      {
        if(alarmSettings[channelIdx] === undefined)
        {
          alarmSettings[channelIdx] = [];
        }
        channel.AlarmSettings.forEach((as, asIdx) =>
        {
          if(alarmSettings[channelIdx][asIdx] === undefined)
          {
            alarmSettings[channelIdx][asIdx] = as;
            alarmSettings[channelIdx][asIdx].Active = false;
          }
        })
      })
      return {
        alarmSettings:alarmSettings
      }
    }
    return null;
  }
  handleAlarmSettingDelete = (e, channelIdx, asIdx)=>
  {
    e.preventDefault();
    alert("Deleting "+ channelIdx + ":" + asIdx)
    API.graphql({query: removeChannelAlarmSetting, variables: {id: this.props.device.id, channelIndex: channelIdx, index: asIdx, expectedVersion:this.props.device._version}}).then(value => {
      console.log(value)
      this.props.refreshDevice();
    }).catch(err=>
    {
      console.log(err)
      alert('Failed');
    })
  }

  handleAlarmSettingAdd = (e, channelIdx)=>
  {
    e.preventDefault();
    this.setState(ps=>
        {
          ps.addAlarmSettingModalOpen = channelIdx+1
          return ps;
        })
  }
  handleAlarmModalClose = ()=>
  {
    this.setState(ps=>
    {
      ps.addAlarmSettingModalOpen = 0
      return ps;
    })
  }

  AlarmLevelSelect = (props)=>
  {
    const {mode, min, max} = props;
    switch (mode)
    {
      case "BOTH":
        return (
            <Slider
                id="#alarm-level"
                connect
                start={[this.state.form.level, this.state.form.level]}
                step={1}
                range={{ min: min, max: max }}
                tooltips
                onChange={(e)=>this.handleSliderChange(e, "level")}
            />)
      case "LOWERING":
        return (
            <Slider
                id="#alarm-level"
                connect={[false, true]}
                start={[this.state.form.level]}
                step={1}
                range={{ min: min, max: max }}
                tooltips
                onChange={(e)=>this.handleSliderChange(e, "level")}
            />)
      case "RAISING":
      default:
        return (
            <Slider
                id="#alarm-level"
                connect={[true, false]}
                start={[this.state.form.level]}
                step={1}
                range={{ min: min, max: max }}
                tooltips
                onChange={(e)=>this.handleSliderChange(e, "level")}
            />)
    }
  }

  handleSliderChange(e, fieldName)
  {
    let form = this.state.form;
    form[fieldName] = Number(e[0]);
    this.setState({form: form});
  }

  handleFormItemChange(e, fieldName)
  {
    let form = this.state.form;
    form[fieldName] = e.target.value;
    this.setState({form: form});
  }

  addNewAlarmSetting(channelIdx)
  {
    console.log(channelIdx)
    console.log(this.state.form)

    let alarmSetting = {
      "Type": "THRESHOLD",
      "Level": this.state.form.level,
      "CustomMessage": this.state.form.enterMessage < 1?undefined:this.state.form.enterMessage,
      "CustomExitMessage": this.state.form.exitMessage < 1?undefined:this.state.form.exitMessage,
      "Hysteresis": 0,
      "KnockInDelay": 0,
      "KnockOutDelay": 0,
      "Direction": this.state.form.alarmType
    }
    API.graphql({query: addChannelAlarmSetting, variables: {id: this.props.device.id, channelIndex: channelIdx, alarmSetting: alarmSetting, expectedVersion:this.props.device._version}}).then(value => {
      this.props.refreshDevice();
      this.handleAlarmModalClose();
    }).catch(err=>
    {
      console.log(err)
      alert('Failed to add new alarm setting, please try again');
    })
  }

  render() {
    const {alarmSettings} = this.state;
    const {device} = this.props;
    return (
      <div>
        <div className="blog-comments__meta text-mutes">
          Alarm Settings
        </div>
        <div className="blog-comments__item d-flex p-3">
          {/* Content */}
          <div className="blog-comments__content">
            {alarmSettings.map((channelAlarmSettings, channelIdx)=> (
              <ListGroup key={channelIdx}>
                <ListGroupItemHeading>
                  Channel {channelIdx} ({device.CurrentState.Channels[channelIdx]?.Name})&nbsp;
                    <span className="text-success" onClick={(e) => this.handleAlarmSettingAdd(e, channelIdx)}>
                      <i className="material-icons">add</i>
                    </span>
                  <Modal open={this.state.addAlarmSettingModalOpen===(channelIdx+1)} toggle={this.handleAlarmModalClose}>
                    <ModalHeader>Add Alarm Setting</ModalHeader>
                    <ModalBody centered="true">
                      <Form>
                        <FormGroup>
                          <label htmlFor="#direction-select">Direction</label>
                          <FormSelect id="#direction-select" value={this.state.form.alarmType} onChange={(e)=>this.handleFormItemChange(e, "alarmType")}>
                            <option value="RAISING">Raising</option>
                            <option value="LOWERING">Lowering</option>
                          </FormSelect>
                        </FormGroup>
                        <FormGroup>
                          <label htmlFor="#alarm-level">Alarm Level(s)</label>
                          <this.AlarmLevelSelect mode={this.state.form.alarmType} min={-40} max={125} initial={[0]}/>
                        </FormGroup>
                        <FormGroup>
                          <label htmlFor="#enter-message">Alarm Enter Custom Message</label>
                          <FormInput id="#enter-message" placeholder="Oh no" value={this.state.form.enterMessage} onChange={(e)=>this.handleFormItemChange(e, "enterMessage")}/>
                        </FormGroup>
                        <FormGroup>
                          <label htmlFor="#exit-message">Alarm Exit Custom Message</label>
                          <FormInput id="#exit-message" placeholder="Woo hoo" value={this.state.form.exitMessage} onChange={(e)=>this.handleFormItemChange(e, "exitMessage")} />
                        </FormGroup>
                        <FormGroup>
                          <Button onClick={()=>this.addNewAlarmSetting(channelIdx)}>Add Alarm Setting</Button>
                        </FormGroup>
                      </Form>
                    </ModalBody>
                  </Modal>
                </ListGroupItemHeading>
                {channelAlarmSettings.map((channelAlarmSetting, asIdx)=>
                {
                  if(channelAlarmSetting.PendingChange)
                  {
                    return (
                        <ListGroupItem key={asIdx}>
                          <abbr title={channelAlarmSetting.PendingDeletion ? "Deletion pending" : "Changes pending"}>
                            <i className="material-icons">{channelAlarmSetting.Direction === "RAISING" ? "trending_up" : "trending_down"}</i> {(device.CurrentState.Channels[channelIdx].RenderSettings.ScaleFactor * channelAlarmSetting.Level) + device.CurrentState.Channels[channelIdx].RenderSettings.Offset}{device.CurrentState.Channels[channelIdx].RenderSettings?.Unit??""} &nbsp;
                          </abbr>
                          <span className="text-danger"
                                onClick={(e) => this.handleAlarmSettingDelete(e, channelIdx, asIdx)}>
                            <i className="material-icons">close</i>
                          </span>
                        </ListGroupItem>)
                  }
                  else
                  {
                    return (
                      <ListGroupItem key={asIdx}>
                        <i className="material-icons">{channelAlarmSetting.Direction==="RAISING"?"trending_up":"trending_down"}</i> {device.CurrentState.Channels[channelIdx].RenderSettings?((device.CurrentState.Channels[channelIdx].RenderSettings.ScaleFactor * channelAlarmSetting.Level) + device.CurrentState.Channels[channelIdx].RenderSettings.Offset):(channelAlarmSetting.Level)}{device.CurrentState.Channels[channelIdx].RenderSettings?.Unit??""} &nbsp;
                        <span className="text-danger"
                              onClick={(e) => this.handleAlarmSettingDelete(e, channelIdx, asIdx)}>
                          <i className="material-icons">close</i>
                        </span>
                      </ListGroupItem>
                    )
                  }
                })}
              </ListGroup>
              )
            )}
          </div>
        </div>
      </div>)
  }
}

const AlarmSettingActions =
  {
    body: AlarmSettingActionMenu,
    title: "Alarm Settings"
  }

export default AlarmSettingActions
